// src/website/components/booking/personalDetails.js
import React, { useEffect, useRef } from "react";
import {
  Box,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useTranslation } from 'react-i18next'; // Import useTranslation

const PersonalDetails = ({ personalDetails, setPersonalDetails }) => {
  const { t } = useTranslation(); // Initialize the translation hook
  
 
  return (
    <Box>
      <Text fontSize="xl" mb={4}>
        {t("enter_personal_details")}
      </Text>
      <VStack spacing={6} align="stretch">
        {/* Name Field */}
        <FormControl isRequired>
          <FormLabel>{t("name")}</FormLabel>
          <Input
            placeholder={t("your_name")}
            value={personalDetails.name}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                name: e.target.value,
              })
            }
          />
        </FormControl>

        {/* Phone Number Field using react-phone-input-2 */}
        <FormControl isRequired>
          <FormLabel>{t("phone_number")}</FormLabel>
          <PhoneInput
            country={"ch"} // Sets default to Switzerland
            value={personalDetails.phone}
            onChange={(phone, country, e, formattedValue) => {
              setPersonalDetails({
                ...personalDetails,
                phone: `+${phone}`, // Ensure phone starts with '+'
              });
            }}
            inputStyle={{
              width: "100%",
              height: "40px",
              paddingLeft: "48px",
              borderRadius: "4px",
              borderColor: "#E2E8F0", // Chakra UI's gray.300
            }}
            buttonStyle={{
              borderColor: "#E2E8F0",
            }}
            containerStyle={{ width: "100%" }}
            disableSearch={false}
            placeholder={t("enter_phone_number")}
          />
        </FormControl>
      </VStack>
    </Box>
  );
};

export default PersonalDetails;
