// src/website/components/features/ImageGalleryDisplay.js

import React, { useEffect, useState, useRef } from "react";
import { db } from "firebaseConfig"; // Ensure this path is correct
import { collection, getDocs } from "firebase/firestore";
import {
  Box,
  Image,
  Spinner,
  Text,
  Flex,
  Container,
  useBreakpointValue,
  IconButton,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

// Import React Slick
import Slider from "react-slick";

// Import Slick Carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import custom CSS for additional styles
import "./ImageGalleryDisplay.css"; // Ensure this CSS file handles any necessary styles

// Import Firebase Storage functions
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const ImageGalleryDisplay = () => {
  const [gallery, setGallery] = useState([]);
  const [galleryWithImages, setGalleryWithImages] = useState([]); // New state for gallery with image URLs
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // References for primary and thumbnail sliders
  const mainSlider = useRef(null);
  const thumbSlider = useRef(null);

  // Determine container width based on screen size
  const containerWidth = useBreakpointValue({ base: "100%", md: "85%" });

  // Determine slider height based on screen size
  const sliderHeight = useBreakpointValue({
    base: "300px",
    md: "500px",
    lg: "500px",
  });

  // Fetch gallery from Firestore
  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const galleryCollection = collection(db, "gallery");
        const gallerySnapshot = await getDocs(galleryCollection);
        const galleryList = gallerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Fetched gallery:", galleryList); // Debugging

        setGallery(galleryList);
      } catch (err) {
        console.error("Error fetching gallery: ", err);
        setError(true);
        setLoading(false);
      }
    };

    fetchGallery();
  }, []);

  // Fetch images from Firebase Storage
  useEffect(() => {
    const fetchGalleryImages = async () => {
      const storage = getStorage(); // Initialize Firebase Storage
      try {
        const updatedGallery = await Promise.all(
          gallery.map(async (image) => {
            if (image.filename) {
              try {
                const imageRef = ref(storage, `gallery/${image.filename}`);
                const url = await getDownloadURL(imageRef);
                return { ...image, imageUrl: url };
              } catch (error) {
                console.error(`Error fetching image for gallery ${image.id}:`, error);
                return { ...image, imageUrl: "/assets/img/gallery/default_gallery.png" };
              }
            } else {
              return { ...image, imageUrl: "/assets/img/gallery/default_gallery.png" };
            }
          })
        );
        setGalleryWithImages(updatedGallery);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching gallery images: ", error);
        setError(true);
        setLoading(false);
      }
    };

    if (gallery.length > 0) {
      fetchGalleryImages();
    }
  }, [gallery]);

  if (loading) {
    return (
      <Flex minH="50vh" justify="center" align="center">
        <Spinner size="xl" color="teal.500" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex minH="50vh" justify="center" align="center">
        <Text fontSize="xl" color="red.500">
          Failed to load gallery.
        </Text>
      </Flex>
    );
  }

  // Slider settings for the main slider
  const mainSettings = {
    asNavFor: thumbSlider.current,
    ref: (slider) => (mainSlider.current = slider),
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    accessibility: false, // Disable accessibility features to prevent focus shifts
    pauseOnHover: true, // Pause autoplay on hover to reduce interruptions
    responsive: [
      {
        breakpoint: 768, // Tablet
        settings: {
          arrows: true, // Keep arrows visible on tablet
        },
      },
      {
        breakpoint: 480, // Mobile
        settings: {
          arrows: true, // Keep arrows visible on mobile
        },
      },
    ],
    // Removed beforeChange callback to prevent automatic synchronization
  };

  // Slider settings for the thumbnail slider
  const thumbSettings = {
    asNavFor: mainSlider.current,
    ref: (slider) => (thumbSlider.current = slider),
    slidesToShow: Math.min(gallery.length, 6),
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: false, // Disable focus on select to prevent focus shifts
    centerMode: true,
    arrows: false,
    accessibility: false, // Disable accessibility features
    responsive: [
      {
        breakpoint: 1024, // Large devices
        settings: {
          slidesToShow: Math.min(gallery.length, 6),
        },
      },
      {
        breakpoint: 768, // Tablet
        settings: {
          slidesToShow: Math.min(gallery.length, 4),
        },
      },
      {
        breakpoint: 480, // Mobile
        settings: {
          slidesToShow: Math.min(gallery.length, 3),
        },
      },
    ],
  };

  // Custom Next Arrow
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <IconButton
        aria-label="Next slide"
        icon={<ChevronRightIcon />}
        position="absolute"
        right="10px"
        top="50%"
        transform="translateY(-50%)"
        zIndex="2"
        onClick={onClick}
        variant="solid"
        colorScheme="purple" // Blue-purple color
        bg="purple.500" // Solid background
        _hover={{ bg: "purple.600", color: "white" }} // Hover effect
        size="xl" // Larger size
        fontSize="24px" // Increased font size for icon
        tabIndex={-1} // Prevent focus via keyboard
      />
    );
  }

  // Custom Prev Arrow
  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <IconButton
        aria-label="Previous slide"
        icon={<ChevronLeftIcon />}
        position="absolute"
        left="10px"
        top="50%"
        transform="translateY(-50%)"
        zIndex="2"
        onClick={onClick}
        variant="solid"
        colorScheme="purple" // Blue-purple color
        bg="purple.500" // Solid background
        _hover={{ bg: "purple.600", color: "white" }} // Hover effect
        size="xl" // Larger size
        fontSize="24px" // Increased font size for icon
        tabIndex={-1} // Prevent focus via keyboard
      />
    );
  }

  return (
    <Flex
      justify="center"
      align="center"
      p={{ base: 0, md: 4 }}
      bg="gray.50"
      mt={{ base: 8, md: 10 }}
    >
      <Container maxW={containerWidth} px={{ base: 0, md: 4 }}>
        {/* Main Slider */}
        <Box
          position="relative"
          width="100%"
          height={sliderHeight}
          mb={4}
          aria-hidden="true" // Hide from accessibility tools
        >
          <Slider {...mainSettings}>
            {galleryWithImages.map((image) => (
              <Box
                key={image.id}
                position="relative"
                width="100%"
                height={sliderHeight}
                cursor="pointer"
                overflow="hidden"
                borderRadius="md"
                _hover={{ transform: "scale(1.02)", transition: "transform 0.3s" }}
                tabIndex={-1} // Prevent focus on slides
                aria-hidden="true" // Hide from accessibility tools
              >
                <Image
                  src={image.imageUrl} // Use the fetched image URL
                  alt={`Gallery Image ${image.id}`}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  transition="opacity 0.3s"
                  _hover={{ opacity: 0.8 }}
                  loading="lazy"
                  draggable="false" // Prevent image dragging which can sometimes affect focus
                  fallbackSrc="/assets/img/gallery/default_gallery.png" // Fallback image
                />
              </Box>
            ))}
          </Slider>
        </Box>

        {/* Thumbnail Slider */}
        <Box width="100%" mb={4} aria-hidden="true">
          <Slider {...thumbSettings}>
            {galleryWithImages.map((image, index) => (
              <Box
                key={image.id}
                p="2px"
                cursor="pointer"
                _hover={{ opacity: 0.8 }}
                onClick={() => {
                  if (mainSlider.current) {
                    mainSlider.current.slickGoTo(index);
                  }
                }}
                tabIndex={-1} // Prevent focus via keyboard
                aria-hidden="true" // Hide from accessibility tools
              >
                <Image
                  src={image.imageUrl} // Use the fetched image URL
                  alt={`Thumbnail ${index + 1}`}
                  width="100%"
                  height="80px"
                  objectFit="cover"
                  borderRadius="md"
                  draggable="false" // Prevent image dragging
                  fallbackSrc="/assets/img/gallery/default_gallery.png" // Fallback image
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    </Flex>
  );
};

// Export the component
export default ImageGalleryDisplay;
