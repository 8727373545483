// src/website/components/MainComponent.js
import React from "react";
import { Box } from "@chakra-ui/react";
import tw from "twin.macro";
import AnimationRevealPage from "website/components/features/AnimationRevealPage.js";
import BackgroundAsImage from "website/components/BackgroundAsImage.js";
import Features from "website/components/features/ServicesThreeColumns.js";
import Pricing from "website/components/features/Pricing";
import ServiceBooking from "website/components/booking/booking.js";
import ImageGallery from "website/components/features/ImageGalleryDisplay";
import GoogleMapWithAddress from "website/components/features/GoogleMapWithAddress";
import Footer from "website/components/features/footer";

import { useTranslation } from 'react-i18next'; // Import useTranslation



const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;


const MainComponent = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <AnimationRevealPage disabled>
      <div id="home">
        <BackgroundAsImage />
      </div>
      <div id="services">
        <Features
          heading={
            <>
              {t("our")} <HighlightedText>{t("services")}</HighlightedText>
            </>
          }
          imageContainerCss={{ padding: "0.5rem !important" }} // Converted twin.macro to Chakra props
          imageCss={{ width: "5rem !important", height: "5rem !important" }}
        />
      </div>
      <div id="pricelist">
        <Pricing />
      </div>
      <div id="booking">
        <ServiceBooking />
      </div>
      <div id="imageGallery">
        <ImageGallery />
      </div>
      <div id="contact">
        <Box>
          <GoogleMapWithAddress
            address={t("address")}
            mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2702.5399941395654!2d7.346723175805258!3d47.36237827116846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4791e7b17492b991%3A0x88a5020464fd87e5!2sMaikel%20Barber!5e0!3m2!1sen!2sgr!4v1727552058733!5m2!1sen!2sgr"
          />
        </Box>
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};

export default MainComponent;
