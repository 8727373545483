// src/components/GoogleMapWithAddress.js

import React from "react";
import { Box, Flex, Text, Link, Icon, useBreakpointValue } from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";
import { FaInstagram, FaTiktok } from "react-icons/fa";

import { useTranslation } from 'react-i18next'; // Import useTranslation

const GoogleMapWithAddress = ({
  address = "Rue Albert Schnyder 4, 2800 Delémont, Switzerland",
  mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2702.5399941395654!2d7.346723175805258!3d47.36237827116846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4791e7b17492b991%3A0x88a5020464fd87e5!2sMaikel%20Barber!5e0!3m2!1sen!2sgr!4v1727552058733!5m2!1sen!2sgr",
}) => {
  const { t } = useTranslation(); // Initialize the translation hook

  // Responsive height for the map
  const mapHeight = useBreakpointValue({ base: "300px", md: "400px", lg: "500px" });

  // Responsive layout: Column on mobile, row on larger screens
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });

  // Alignment based on layout
  const alignItems = useBreakpointValue({ base: "flex-start", md: "center" });

  return (
    <Box w="100%">
      <Flex direction={flexDirection} align={alignItems} justify="center">
        {/* Google Map */}
        <Box flex="1" mb={{ base: 4, md: 0 }} mr={{ base: 0, md: 8 }}>
          <Box
            as="iframe"
            src={mapSrc}
            width="100%"
            height={mapHeight}
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            borderRadius="md"
          ></Box>
          <Link
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
            color="teal.500"
            isExternal
            fontSize="md"
            mt={2}
            display="block"
          >
            {t("view_on_google_maps")}
          </Link>
        </Box>

        {/* Address Details */}
        <Box flex="1">
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {t("our_address")}
          </Text>
          <Text fontSize="md" mb={4}>
            {address}
          </Text>
          {/* Phone Number */}
          <Flex align="center" mb={4}>
            <Link href="tel:+41789390919" color="teal.600" aria-label={t("phone_number_icon")}>
              <Icon as={PhoneIcon} w={5} h={5} color="teal.500" mr={4} />
            </Link>
            <Box>
              <Text fontSize="md" fontWeight="bold">
                {t("phone_number")}
              </Text>
              <Text fontSize="md">
                <Link href="tel:+41789390919" color="teal.600">
                  +41 78 939 09 19
                </Link>
              </Text>
            </Box>
          </Flex>
          {/* Instagram */}
          <Flex align="center" mb={4}>
            <Icon as={FaInstagram} w={6} h={6} color="teal.500" mr={4} aria-label={t("instagram_icon")} />
            <Box>
              <Text fontSize="md" fontWeight="bold">
                {t("instagram")}
              </Text>
              <Text fontSize="md">
                <Link
                  href="https://www.instagram.com/maikle_barber"
                  color="teal.600"
                  isExternal
                >
                  @maikle_barber
                </Link>
              </Text>
            </Box>
          </Flex>

          {/* TikTok */}
          <Flex align="center">
            <Icon as={FaTiktok} w={6} h={6} color="teal.500" mr={4} aria-label={t("tiktok_icon")} />
            <Box>
              <Text fontSize="md" fontWeight="bold">
                {t("tiktok")}
              </Text>
              <Text fontSize="md">
                <Link
                  href="https://www.tiktok.com/@maikelbarber7"
                  color="teal.600"
                  isExternal
                >
                  @maikelbarber7
                </Link>
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default GoogleMapWithAddress;
