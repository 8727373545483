// src/components/PricesTable.js 

'use client';
/* eslint-disable */

import {
  Box,
  Flex,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  IconButton,
  Tooltip,
  Spinner,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Avatar,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import * as React from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi'; // Import edit and trash icons

import { useTranslation } from 'react-i18next'; // Import useTranslation

const columnHelper = createColumnHelper();

// Define columns for the services table
const columns = (t) => [
  columnHelper.display({
    id: 'profile',
    cell: (info) => (
      <Avatar
        src={info.row.original.profileUrl || '/assets/img/profile/default_profile.png'} // Use default image if profileUrl is null
        name={info.row.original.service}
        size="sm"
        mr="2"
      />
    ),
  }),
  columnHelper.accessor('service', {
    id: 'service',
    header: () => (
      <Text
        justifyContent="space-between"
        align="center"
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        color="gray.400"
        textAlign="left"
      >
        {t('prices_table_service')}
      </Text>
    ),
    cell: (info) => (
      <Flex align="center">
        <Tooltip label={info.getValue()} aria-label={t('prices_table_service_tooltip')}>
          <Text
            color={useColorModeValue('secondaryGray.900', 'white')}
            fontSize={{ base: '12px', md: '14px', lg: '12px' }}
            fontWeight="700"
            whiteSpace="nowrap" // Set to 'normal' if you want wrapping
            overflow="hidden"   // Set to 'visible' if you want to remove truncation
            textOverflow="ellipsis" // Remove if not truncating
            // Remove or adjust as needed
          >
            {info.getValue()}
          </Text>
        </Tooltip>
      </Flex>
    ),
  }),
  columnHelper.accessor('price', {
    id: 'price',
    header: () => (
      <Text
        justifyContent="space-between"
        align="center"
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        color="gray.400"
        textAlign="left"
      >
        {t('prices_table_price_chf')}
      </Text>
    ),
    cell: (info) => (
      <Text
        color={useColorModeValue('secondaryGray.900', 'white')}
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        fontWeight="700"
        whiteSpace="nowrap" // Set to 'normal' if you want wrapping
        overflow="hidden"   // Set to 'visible' if you want to remove truncation
        textOverflow="ellipsis" // Remove if not truncating
         // Remove or adjust as needed
      >
        {info.getValue()} CHF
      </Text>
    ),
  }),
  // Actions Column
  columnHelper.display({
    id: 'actions',
    header: () => (
      <Text
        justifyContent="space-between"
        align="center"
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        color="gray.400"
        textAlign="center"
      >
        {t('prices_table_actions')}
      </Text>
    ),
    cell: (info) => (
      <ActionsCell serviceData={info.row.original} />
    ),
  }),
];

// Component for Actions Cell
function ActionsCell({ serviceData }) {
  const { t } = useTranslation(); // Initialize the translation hook
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentServiceId, setCurrentServiceId] = React.useState(null);
  const [currentServiceName, setCurrentServiceName] = React.useState('');
  const [currentServicePrice, setCurrentServicePrice] = React.useState('');

  // Store the service to be deleted
  const handleDeleteClick = () => {
    setCurrentServiceId(serviceData.id);
    setCurrentServiceName(serviceData.service);
    setCurrentServicePrice(serviceData.price);
    onOpen();
  };

  return (
    <>
      <Flex justify="center">
        {/* Edit Button */}
        <Tooltip label={t('prices_table_edit_service_tooltip')} aria-label={t('prices_table_edit_service_tooltip')}>
          <IconButton
            icon={<FiEdit />}
            size="sm"
            variant="ghost"
            colorScheme="blue"
            onClick={() => serviceData.onEdit && serviceData.onEdit(serviceData)}
            aria-label={t('prices_table_edit_service_tooltip')}
            mr={2}
          />
        </Tooltip>

        {/* Delete Button */}
        <Tooltip label={t('prices_table_delete_service_tooltip')} aria-label={t('prices_table_delete_service_tooltip')}>
          <IconButton
            icon={<FiTrash />}
            size="sm"
            variant="ghost"
            colorScheme="red"
            onClick={handleDeleteClick}
            aria-label={t('prices_table_delete_service_tooltip')}
          />
        </Tooltip>
      </Flex>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('prices_table_delete_service_header')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {t('prices_table_delete_service_body')}<br/>
              <strong>{currentServiceName}</strong> priced at <strong>{currentServicePrice} CHF</strong>
              
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              {t('prices_table_cancel')}
            </Button>
            <Button colorScheme="red" onClick={() => {
              serviceData.onDelete(serviceData.id);
              onClose();
            }}>
              {t('prices_table_delete')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default function PricesTable(props) {
  const { t } = useTranslation(); // Initialize the translation hook
  const { tableData, fetching, fetchError, onEditUser, onDeleteUser } = props;
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  // Enhance table data with onEdit and onDelete callbacks
  const enhancedTableData = React.useMemo(() => {
    return tableData.map((service) => ({
      ...service,
      onEdit: onEditUser, // Assign the callback function for editing
      onDelete: onDeleteUser, // Assign the callback function for deleting
    }));
  }, [tableData, onEditUser, onDeleteUser]);

  const table = useReactTable({
    data: enhancedTableData,
    columns: columns(t), // Pass the translation function to columns
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ base: 'auto', lg: 'hidden' }} // Allow horizontal scroll on small screens
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize={{ base: '20px', md: '22px' }}
          fontWeight="700"
          lineHeight="100%"
        >
          {t('prices_table_header_services')}
        </Text>
      </Flex>

      {/* Display Loading Spinner */}
      {fetching && (
        <Flex justify="center" align="center" mt="20px" mb="20px">
          <Spinner size="lg" color="blue.500" />
        </Flex>
      )}

      {/* Display Fetch Error */}
      {fetchError && (
        <Alert status="error" mb="20px">
          <AlertIcon />
          {fetchError}
        </Alert>
      )}

      {/* Display Table Only If Data is Available and Not Fetching */}
      {!fetching && !fetchError && (
        <Box overflowX="auto">
          <Table
            variant="simple"
            color="gray.500"
            mb="24px"
            mt="12px"
            minW="100%" // Ensure table takes full width
            tableLayout="fixed" // Fix table layout for consistent column widths
          >
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor={header.column.getCanSort() ? "pointer" : "default"}
                        onClick={header.column.getToggleSortingHandler()}
                        paddingY="8px" // Reduced vertical padding
                        paddingX="12px" // Reduced horizontal padding
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ base: '10px', md: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ base: '12px', md: '14px', lg: '12px' }}
                          paddingY="6px" // Reduced vertical padding
                          paddingX="12px" // Reduced horizontal padding
                          borderColor="transparent"
                          overflow="hidden" // Ensure content doesn't overflow
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </Card>
  );
}
