// pricelist.js
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "website/components/misc/Headings.js";
import { Container as ContainerBase } from "website/components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "website/images/checkbox-circle.svg";
import { db } from "firebaseConfig"; // Ensure this path is correct
import { collection, getDocs } from "firebase/firestore";
import { Text } from "@chakra-ui/react"; // Replace with your preferred Text component if not using Chakra UI

import { useTranslation } from 'react-i18next'; // Import useTranslation

// Styled Components
const Container = tw(ContainerBase)`bg-white text-gray-900 -mx-8 px-8 sm:px-8`;

const HeaderContainer = tw.div`mt-0 w-full flex flex-col items-center`;

const Heading = tw(SectionHeading)`text-center`;

const ServicesContainer = tw.div`flex justify-center flex-wrap`; // Renamed for clarity

const ServiceCard = styled.div`
  ${tw`w-full lg:w-2/3 bg-white rounded-lg shadow-sm p-0 sm:py-6 sm:px-10 lg:py-10 xl:px-16 flex flex-col justify-between mt-8 first:mt-0 lg:mt-0`}
`;


const ServiceTable = styled.table`
  ${tw`mt-4 border-t border-gray-200 py-4 px-6 sm:px-10 lg:px-10 xl:px-16 w-full`}
  
  thead {
    ${tw`bg-gray-100`}
  }

  th {
    ${tw`py-3 text-left text-sm font-medium text-gray-500`}
  }

  tbody {
    ${tw``}
  }

  tr.service {
    ${tw`border-b border-gray-200 last:border-b-0`}
  }

  td {
    ${tw`py-2 text-sm text-gray-700`}
  }

  .icon-cell {
    ${tw`w-6`}
    .icon {
      ${tw`w-6 h-6 text-gray-600`}
    }
  }

  .service-name {
    ${tw`pl-3 font-semibold text-primary-900 tracking-wide`}
  }

  .service-price {
    ${tw`pl-3 font-semibold text-primary-900 tracking-wide`}
  }
`;

// Main Component
export default function PriceList({ heading = "price_list" }) { // Use translation key as default
  const { t } = useTranslation(); // Initialize the translation hook

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch services from Firestore
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesCollection = collection(db, "services");
        const servicesSnapshot = await getDocs(servicesCollection);
        const servicesList = servicesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log("Fetched services:", servicesList); // Debugging

        setServices(servicesList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching services: ", err);
        setError("fetch_services_error"); // Use translation key
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) {
    return (
      <Container>
        <HeaderContainer>
          <Heading>{t(heading)}</Heading>
          <Text>{t("loading")}</Text>
        </HeaderContainer>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <HeaderContainer>
          <Heading>{t(heading)}</Heading>
          <Text color="red.500">{t(error)}</Text>
        </HeaderContainer>
      </Container>
    );
  }

  // Ensure that services is an array
  if (!Array.isArray(services)) {
    console.error("Services data is not an array:", services);
    return (
      <Container>
        <HeaderContainer>
          <Heading>{t(heading)}</Heading>
          <Text color="red.500">{t("invalid_data_format")}</Text>
        </HeaderContainer>
      </Container>
    );
  }

  return (
    <Container>
      <HeaderContainer>
        <Heading>{t(heading)}</Heading>
      </HeaderContainer>
      <ServicesContainer>
        <ServiceCard>
          <ServiceTable>
            <thead>
              <tr>
                <th className="icon-cell"></th>
                <th className="service-name">{t("service")}</th>
                <th className="service-price">{t("price")}</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr className="service" key={service.id}>
                  <td className="icon-cell">
                    <CheckboxIcon className="icon" />
                  </td>
                  <td className="service-name">{service.service}</td>
                  <td className="service-price">{service.price} CHF</td>
                </tr>
              ))}
            </tbody>
          </ServiceTable>
        </ServiceCard>
      </ServicesContainer>
    </Container>
  );
}
