// src/website/components/booking/barberSelection.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Radio,
  Flex,
  Image,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { collection, getDocs, query, where } from "firebase/firestore"; // Import 'query' and 'where'
import { getStorage, ref, getDownloadURL } from "firebase/storage"; // Import Firebase Storage functions
import { db } from "firebaseConfig";

import { useTranslation } from 'react-i18next'; // Import useTranslation

const BarberSelection = ({ selectedBarber, setSelectedBarber }) => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [barbers, setBarbers] = useState([]);
  const [barbersWithImages, setBarbersWithImages] = useState([]); // New state for barbers with image URLs
  const [loadingBarbers, setLoadingBarbers] = useState(true);
  const [errorBarbers, setErrorBarbers] = useState(null);
  
  const toast = useToast();

  useEffect(() => {
    const fetchBarbers = async () => {
      try {
        const barbersCollection = collection(db, "users");
        // Create a query against the collection to filter barbers with status "Enabled"
        const q = query(barbersCollection, where("status", "==", "Enabled"));
        const barbersSnapshot = await getDocs(q);
        const barbersList = barbersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBarbers(barbersList);
      } catch (error) {
        console.error("Error fetching barbers: ", error);
        setErrorBarbers("fetch_barbers_error");
        setLoadingBarbers(false);
        toast({
          title: t("error"),
          description: t("fetch_barbers_error"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchBarbers();
  }, [toast, t]);

  useEffect(() => {
    const storage = getStorage(); // Initialize Firebase Storage

    const fetchBarberImages = async () => {
      try {
        const updatedBarbers = await Promise.all(
          barbers.map(async (barber) => {
            if (barber.profile) {
              try {
                const barberRef = ref(storage, `profiles/${barber.profile}`);
                const url = await getDownloadURL(barberRef);
                return { ...barber, imageUrl: url };
              } catch (error) {
                console.error(`Error fetching image for barber ${barber.id}:`, error);
                return { ...barber, imageUrl: "/assets/img/profile/default_profile.png" };
              }
            } else {
              return { ...barber, imageUrl: "/assets/img/profile/default_profile.png" };
            }
          })
        );
        setBarbersWithImages(updatedBarbers);
        setLoadingBarbers(false);
      } catch (error) {
        console.error("Error fetching barber images: ", error);
        setErrorBarbers("fetch_barbers_error");
        setLoadingBarbers(false);
        toast({
          title: t("error"),
          description: t("fetch_barbers_error"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (barbers.length > 0) {
      fetchBarberImages();
    }
  }, [barbers, toast, t]);

  if (loadingBarbers) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
        <Text mt={4}>{t("loading_barbers")}</Text>
      </Box>
    );
  }

  if (errorBarbers) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">{t(errorBarbers)}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text fontSize="xl" mb={4}>
        {t("select_barber")}
      </Text>
      <SimpleGrid columns={[1, 2]} spacing={4}>
        {barbersWithImages.map((barber) => (
          <Radio
            key={barber.id}
            value={barber.id}
            onChange={() => setSelectedBarber(barber)}
            isChecked={selectedBarber?.id === barber.id}
            p={0}
            borderRadius="md"
          >
            <Flex
              borderWidth="1px"
              borderRadius="md"
              p={1}
              align="center"
              cursor="pointer"
              _hover={{ bg: "green.100" }}
              bg={
                selectedBarber?.id === barber.id
                  ? "green.200"
                  : "white"
              } // Light green highlight
              w="100%"
            >
              <Image
                src={barber.imageUrl} 
                boxSize="65px" // Adjust size as needed
                objectFit="cover"
                borderRadius="full"
                mr={4}
                fallbackSrc="/assets/img/profile/default_profile.png" // Fallback image
              />
              <Box>
                <Text fontWeight="bold">{barber.name}</Text>
                <Text fontSize="sm" color="gray.500">
                  {barber.phone}
                </Text>
              </Box>
            </Flex>
          </Radio>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default BarberSelection;
