// src/views/auth/AuthContext.jsx

import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { auth, db } from '../../firebaseConfig'; // Adjust the path to your firebaseConfig
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import {
  Spinner,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';

// Create a Context
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Custom Spinner with Pulsing Effect
const OverlaySpinner = () => {
  const spinnerColor = useColorModeValue('teal.500', 'teal.300');
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      bg={useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(0, 0, 0, 0.8)')}
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="9999"
    >
      <Spinner
        thickness="4px"
        speed="0.8s"
        emptyColor="gray.200"
        color={spinnerColor}
        size="xl"
      />
    </Box>
  );
};

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null); // Firebase user
  const [userData, setUserData] = useState(null);       // Additional user data from Firestore
  const [loading, setLoading] = useState(true);         // Loading state
  const [userDocUnsubscribe, setUserDocUnsubscribe] = useState(null); // Firestore listener unsubscribe

  // Function to fetch and listen to user data in real-time
  const fetchUserData = (user) => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUserData(docSnapshot.data());
        } else {
          console.error('No user document found in Firestore.');
          setUserData(null);
        }
      }, (error) => {
        console.error('Error fetching user data:', error);
        setUserData(null);
      });
      setUserDocUnsubscribe(() => unsubscribe);
    } else {
      setUserData(null);
      if (userDocUnsubscribe) {
        userDocUnsubscribe();
        setUserDocUnsubscribe(null);
      }
    }
  };

  // Function to manually refresh user data
  const refreshUserData = async () => {
    if (currentUser) {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.error('No user document found in Firestore.');
          setUserData(null);
        }
      } catch (error) {
        console.error('Error refreshing user data:', error);
        setUserData(null);
      }
    } else {
      setUserData(null);
    }
  };

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      fetchUserData(user);
      setLoading(false);
    });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeAuth();
      if (userDocUnsubscribe) {
        userDocUnsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on mount

  // Memoize context value to prevent unnecessary re-renders
  const value = useMemo(() => ({
    currentUser,
    userData,
    refreshUserData, // Provide the refresh function
  }), [currentUser, userData]);

  return (
    <AuthContext.Provider value={value}>
      {children}
      {loading && <OverlaySpinner />}
    </AuthContext.Provider>
  );
};
