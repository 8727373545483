// src/PrivateRoute.jsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext.jsx'; // Adjust the path as necessary

const PrivateRoute = ({ children, requiredRole }) => {
  const { currentUser, userData } = useAuth();

  if (!currentUser) {
    // User is not authenticated
    return <Navigate to="/auth/sign-in" replace />;
  }

  if (userData) {
    if (userData.status !== 'Enabled') {
      // User is authenticated but not enabled
      return <Navigate to="/auth/sign-in" replace />;
    }

    if (requiredRole && userData.userType !== requiredRole) {
      // User does not have the required role
      return <Navigate to="/auth/sign-in" replace />;
    }
  } else {
    // If userData is not available
    return <Navigate to="/auth/sign-in" replace />;
  }

  // User is authenticated, enabled, and has the required role
  return children;
};

export default PrivateRoute;
