// src/components/navbar/NavbarLinks.js

// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react'; // Added useState and useEffect
// Icons
import { MdNotificationsNone } from 'react-icons/md';
import routes from 'routes';
import { useAuth } from 'views/auth/AuthContext'; // Adjust the path accordingly
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../firebaseConfig'; // Adjust the path accordingly
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from 'react-i18next'; // Import useTranslation

// ** New Imports for Firebase Storage **
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export default function HeaderLinks(props) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const { currentUser, userData } = useAuth(); // Utilize AuthContext
  const { t, i18n } = useTranslation(); // Initialize the translation hook
  const navigate = useNavigate();

  // ** New State for Profile Picture URL **
  const [profilePicUrl, setProfilePicUrl] = useState('');

  // ** New useEffect to Fetch Profile Picture URL from Firebase Storage **
  useEffect(() => {
    if (userData?.profile) {
      const storage = getStorage();
      const profileRef = ref(storage, `profiles/${userData.profile}`);
      getDownloadURL(profileRef)
        .then((url) => {
          setProfilePicUrl(url);
        })
        .catch((error) => {
          console.error('Error fetching profile picture:', error);
          // Optionally, set a default profile picture URL here
          setProfilePicUrl(''); // Or a default image URL
        });
    }
  }, [userData]);

  // Chakra UI Color Modes
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  // Logout Handler
  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Optionally, you can add a success notification or redirect the user
      navigate('/');
      console.log(t('logout_success'));
    } catch (error) {
      console.error('Error signing out:', error);
      // Optionally, display an error notification to the user
      console.log(t('logout_error'));
    }
  };

  // **Role-Based Route Filtering**
  const getFilteredRoutes = () => {
    if (!userData || !userData.userType) {
      // If userData is not available, return an empty array or handle accordingly
      return [];
    }

    const userType = userData.userType;

    // Filter routes based on userType
    const filteredRoutes = routes.filter(route => {
      if (route.layout !== '/admin') return false; // Only consider admin layout routes

      if (route.roles && route.roles.length > 0) {
        // If roles are defined, check if the userType is included
        return route.roles.includes(userType);
      }

      // If no roles are defined, assume the route is accessible by any authenticated user
      return true;
    });

    return filteredRoutes;
  };

  const filteredRoutes = getFilteredRoutes();

   // Handle language change
   const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

    // Determine the current country code based on the selected language
    const getCurrentCountryCode = () => {
      switch (i18n.language) {
        case 'fr':
          return 'CH';
        case 'en':
        default:
          return 'GB';
      }
    };

  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      bg={menuBg} // Solid background
      p="10px"
      borderRadius="md"
      boxShadow={shadow}
      height="40px" // Adjusted height to fit within Navbar
      minWidth="140px" // Ensures a minimum width for better alignment
      justifyContent="flex-end" // Align items to the right
    >
      {/* Ethereum Balance Display */}
      {/* You can integrate Ethereum balance here as per your requirements */}

      {/* **Pass Filtered Routes to SidebarResponsive** */}
      <SidebarResponsive routes={filteredRoutes} />

      {/* Notifications Menu */}
      <Menu>
        <MenuButton p="0px" aria-label={t('notifications')}>
          <Icon
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            mr="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          minW={{ base: '360px', md: '400px', xl: '450px' }}
        >
          <Flex w="100%" mb="20px" justifyContent="space-between">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              {t('notifications')}
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              cursor="pointer"
            >
              {t('mark_all_read')}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            {/* Replace the hardcoded notifications with dynamic data if available */}
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info={t('notification_appointment_booked')} />
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info={t('notification_new_message')} />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>

      {/* Language Selection Menu */}
      <Menu>
        <MenuButton ml="10px" aria-label={t('language')}>
          <ReactCountryFlag
            countryCode={getCurrentCountryCode()}
            svg
            style={{
              width: '1.5em',
              height: '1.5em',
            }}
            title={i18n.language === 'fr' ? 'French' : 'English'}
          />
        </MenuButton>
        <MenuList
          boxShadow="md"
          p="10px"
          borderRadius="20px"
          bg={useColorModeValue('white', 'gray.700')}
          border="none"
          mt="10px"
          minW="120px"
        >
          <MenuItem
            onClick={() => handleLanguageChange('fr')}
            _hover={{ bg: 'teal.100' }}
            display="flex"
            alignItems="center"
          >
            <ReactCountryFlag
              countryCode="CH"
              svg
              style={{ marginRight: '8px', width: '1.5em', height: '1.5em' }}
              title="French"
            />
            {t('french')}
          </MenuItem>
          <MenuItem
            onClick={() => handleLanguageChange('en')}
            _hover={{ bg: 'teal.100' }}
            display="flex"
            alignItems="center"
          >
            <ReactCountryFlag
              countryCode="GB"
              svg
              style={{ marginRight: '8px', width: '1.5em', height: '1.5em' }}
              title="English"
            />
            {t('english')}
          </MenuItem>
          
        </MenuList>
      </Menu>

      {/* User Avatar Menu */}
      <Menu>
        <MenuButton p="0px" ml="10px" aria-label={t('user_menu')}>
          <Avatar
            _hover={{ cursor: 'pointer' }}
            name={userData?.name || t('user')}
            src={profilePicUrl} // Updated to use profilePicUrl from Firebase Storage
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; {t('hey_user', { name: userData?.name || t('user') })}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* Add more menu items here if needed */}
            <MenuItem
              onClick={handleLogout}
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">{t('log_out')}</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
