// src/views/auth/SignIn.jsx

import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

// Firebase imports
import { auth, db } from "firebaseConfig"; // Adjust the path as necessary
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
// Import useAuth from AuthContext
import { useAuth } from '../AuthContext.jsx'; // Adjust the path as necessary

import { useTranslation } from 'react-i18next'; // Import useTranslation

function SignIn() {
  // Initialize the translation hook
  const { t } = useTranslation();

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  // State management for authentication
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate(); // For navigation

  // Access Auth Context
  const { currentUser, userData } = useAuth();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error state

    // Basic validation
    if (!email || !password) {
      setError(t("error_enter_email_and_password"));
      return;
    }

    setLoading(true); // Start loading

    try {
      // Firebase authentication
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("User signed in:", user);

      // Retrieve user document from Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("User data from Firestore:", userData);

        // Check if user status is "Enabled"
        if (userData.status === "Enabled") {
          // Proceed to dashboard or desired route
          navigate("/admin"); // Adjust the route as needed
        } else {
          // User is disabled, sign them out
          await auth.signOut();
          setError(t("error_account_disabled"));
        }
      } else {
        // User document does not exist
        console.error("No user document found in Firestore.");
        // Optionally, sign out the user and show an error
        await auth.signOut();
        setError(t("error_no_user_data_found"));
      }
    } catch (err) {
      console.error("Error during sign in:", err);
      // Map Firebase error codes to translation keys
      const errorMessages = {
        "auth/user-not-found": "error_user_not_found",
        "auth/wrong-password": "error_wrong_password",
        "auth/invalid-email": "error_invalid_email",
        "auth/user-disabled": "error_user_disabled",
        // Add more mappings as needed
      };
      const messageKey = errorMessages[err.code] || "error_incorrect_password_or_email";
      setError(t(messageKey));
    } finally {
      setLoading(false); // End loading
    }
  };

  // Redirect if already logged in and user is enabled
  useEffect(() => {
    if (currentUser && userData && userData.status === "Enabled") {
      navigate("/admin");
    }
  }, [currentUser, userData, navigate]);

  // Forgot Password Modal Controls
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [resetEmail, setResetEmail] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState("");
  const [resetLoading, setResetLoading] = useState(false);

  const handlePasswordReset = async () => {
    setResetError("");
    setResetSuccess("");

    if (!resetEmail) {
      setResetError(t("error_enter_email"));
      return;
    }

    setResetLoading(true);

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetSuccess(t("password_reset_email_sent"));
    } catch (err) {
      console.error("Error sending password reset email:", err);
      const resetErrorMessages = {
        "auth/user-not-found": "error_user_not_found",
        "auth/invalid-email": "error_invalid_email",
        // Add more mappings as needed
      };
      const messageKey = resetErrorMessages[err.code] || "error_unable_to_send_reset_email";
      setResetError(t(messageKey));
    } finally {
      setResetLoading(false);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {t("sign_in")}
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl as="form" onSubmit={handleSubmit}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              {t("email")}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              {t("password")}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {error && (
              <Alert status="error" mb="24px">
                <AlertIcon />
                {error}
              </Alert>
            )}
            <Flex justifyContent="space-between" align="center" mb="24px">
              <Text
                color={textColorBrand}
                fontSize="sm"
                
                fontWeight="500"
                cursor="pointer"
                onClick={onOpen}
                _hover={{ textDecoration: 'underline' }}
              >
                {t("forgot_password")}
              </Text>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50px"
              mb="24px"
              type="submit"
              isDisabled={loading}
              leftIcon={
                loading ? (
                  <Spinner size="sm" color="white" />
                ) : null
              }
            >
              {loading ? t("signing_in") : t("sign_in_button")}
            </Button>
          </FormControl>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              {t("or")}
            </Text>
            <HSeparator />
          </Flex>
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            // Add onClick handler for Google Sign-In if needed
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            {t("sign_in_with_google")}
          </Button>
        </Flex>
      </Flex>

      {/* Forgot Password Modal */}
      <Modal isOpen={isOpen} onClose={() => {
        onClose();
        setResetEmail("");
        setResetError("");
        setResetSuccess("");
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("forgot_password")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>{t("enter_your_email")}</FormLabel>
              <Input
                type="email"
                placeholder={t("email")}
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </FormControl>
            {resetError && (
              <Alert status="error" mt="4">
                <AlertIcon />
                {resetError}
              </Alert>
            )}
            {resetSuccess && (
              <Alert status="success" mt="4">
                <AlertIcon />
                {resetSuccess}
              </Alert>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handlePasswordReset}
              isLoading={resetLoading}
            >
              {t("send_reset_email")}
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                setResetEmail("");
                setResetError("");
                setResetSuccess("");
              }}
            >
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DefaultAuth>
  );
}

export default SignIn;
