// src/routes.js

import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdPhoto,
  MdPeople,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Accounts from 'views/admin/settings/accounts';
import ImageGallery from 'views/admin/profile/imageGallery';
import MyProfile from 'views/admin/profile/myprofile';
import Services from 'views/admin/settings/services';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

// PrivateRoute Import
import PrivateRoute from 'views/auth/PrivateRoute'; // Adjust the path as necessary

const routes = [
  {
    name: 'route_appointments',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: (
      <PrivateRoute>
        <MainDashboard />
      </PrivateRoute>
    ),
    roles: ['Admin', 'Standard'], // Accessible by both Admin and Standard users
  },
  {
    name: 'route_accounts',
    layout: '/admin',
    path: '/accounts',
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: (
      <PrivateRoute requiredRole="Admin">
        <Accounts />
      </PrivateRoute>
    ),
    roles: ['Admin'], // Accessible only by Admin users
    secondary: true,
  },
  {
    name: 'route_services',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/services',
    component: (
      <PrivateRoute requiredRole="Admin">
        <Services />
      </PrivateRoute>
    ),
    roles: ['Admin'], // Accessible only by Admin users
  },
  {
    name: 'route_gallery',
    layout: '/admin',
    path: '/images-gallery',
    icon: <Icon as={MdPhoto} width="20px" height="20px" color="inherit" />,
    component: (
      <PrivateRoute requiredRole="Admin">
        <ImageGallery />
      </PrivateRoute>
    ),
    roles: ['Admin'], // Accessible only by Admin users
  }, 
  {
    name: 'route_my_profile',
    layout: '/admin',
    path: '/my-profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: (
      <PrivateRoute>
        <MyProfile />
      </PrivateRoute>
    ),
    roles: ['Admin', 'Standard'], // Accessible by both Admin and Standard users
  },
  {
    name: 'route_sign_in',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
    roles: [], // Public route
  },
];

export default routes;
