// src/website/components/booking/CustomDatePicker.jsx

import React from "react";
import { Input, FormControl } from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

// Import locales from date-fns
import { enUS, fr } from 'date-fns/locale';

// Import custom CSS
import "./CustomDatePicker.css";

const CustomDatePicker = ({ selectedDate, onChange }) => {
  const { t, i18n } = useTranslation(); // Initialize the translation hook

  // Define color modes for the input
  const inputBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  // Determine the locale based on the current language
  const locale = i18n.language === 'fr' ? fr : enUS;

  return (
    <FormControl position="relative">
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        dateFormat={i18n.language === 'fr' ? "dd/MM/yyyy" : "yyyy-MM-dd"} // Adjust date format based on language
        locale={locale} // Set the locale for calendar
        customInput={
          <Input
            bg={inputBg}
            borderColor={borderColor}
            _hover={{ borderColor: "gray.400" }}
            _focus={{ borderColor: "blue.500", boxShadow: "outline" }}
            placeholder={t("select_date")} // Translated placeholder
          />
        }
        // Remove popperModifiers and popperPlacement
        // Optional: Add a className for additional styling if needed
        popperClassName="react-datepicker-popper-custom"
      />
    </FormControl>
  );
};

export default CustomDatePicker;
