// src/website/components/booking/bookingConfirmation.js
import React from "react";
import { Box, Text, VStack, HStack, Divider, Card, Heading, CardBody } from "@chakra-ui/react";
import { FaCut, FaUserTie, FaCalendarAlt } from "react-icons/fa";
import { format } from "date-fns"; // Ensure date-fns is installed

import { useTranslation } from 'react-i18next'; // Import useTranslation

const BookingConfirmation = ({
  bookingCode,
  selectedService,
  selectedBarber,
  selectedDate,
  selectedTime,
}) => {
  const { t } = useTranslation(); // Initialize the translation hook

  // Helper function to format date
  const formatDate = (date) => {
    return format(new Date(date), "MMMM do, yyyy"); // e.g., 'September 25th, 2023'
  };

  // Helper function to format time in 24-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const date = new Date();
    date.setHours(parseInt(hour, 10), parseInt(minute, 10));
    return format(date, "HH:mm"); // e.g., '14:30'
  };

  return (
    <Box>
      {/* Appointment Booked Title */}
      <Heading as="h2" size="lg" mb={2}>
        {t("appointment_booked")}
      </Heading>
      
      {/* Booking Code Label */}
      <Text fontSize="md" color="gray.500" mb={1}>
      {t("your_booking_code")}
      </Text>
      
      {/* Booking Code */}
      <Text fontSize="3xl" color="teal.500" mb={6}>
        {bookingCode}
      </Text>

      {/* Booking Details Card */}
      <Card
        shadow="sm"
        borderWidth="1px"
        borderRadius="md"
        maxW="400px"
        mx="auto"
        p={4}
      >
        <CardBody>
          <VStack spacing={4} align="start">
            {/* Service */}
            <HStack spacing={3}>
              <FaCut color="teal" size={20} aria-label={t("service_icon")} />
              <Text fontWeight="bold">{t("service")}:</Text>
              <Text>{selectedService.service}</Text>
            </HStack>
            <Divider />

            {/* Barber */}
            <HStack spacing={3}>
              <FaUserTie color="teal" size={20} aria-label={t("barber_icon")} />
              <Text fontWeight="bold">{t("barber")}:</Text>
              <Text>{selectedBarber.name}</Text>
            </HStack>
            <Divider />

            {/* Date & Time */}
            <HStack spacing={3}>
              <FaCalendarAlt color="teal" size={20} aria-label={t("date_time_icon")} />
              <Text fontWeight="bold">{t("date")}:</Text>
              <Text>
                {`${formatDate(selectedDate)} at ${formatTime(selectedTime)}`}
              </Text>
            </HStack>
          </VStack>
        </CardBody>
      </Card>

      {/* Success Message */}
      <Text mt={6} fontSize="md" color="green.500" textAlign="center">
       {t("booking_complete_message")}
      </Text>
    </Box>
  );
};

export default BookingConfirmation;
