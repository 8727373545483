// myprofile.jsx

// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import ProfilePicture from "./profilePicture.js";
import WorkingHours from "./WorkingHours.jsx"; // Import the updated component

// Assets
import banner from "assets/img/auth/banner.png";
import React from "react";

export default function Overview() {
  return (
    <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr 1fr", // Two columns: WorkingHours and ProfilePicture
        }}
        templateRows={{
          base: "repeat(2, auto)",
          lg: "1fr",
        }}
        gap={{ base: "0px", xl: "20px" }}
      >
        {/* Working Hours Component */}
        <WorkingHours />

        {/* Profile Picture Component */}
        <ProfilePicture
          gridArea={{
            base: "2 / 1 / 3 / 2", // Adjusted for mobile layout
            lg: "1 / 2 / 2 / 3",
          }}
          banner={banner}
        />
      </Grid>
    </Box>
  );
}
