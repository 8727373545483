// MainComponent.js
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import backgroundImage from "../images/background.webp";

import CustomNavbar from "./features/CustomNavbar"; // Import the updated CustomNavbar
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import { FaCalendarAlt } from 'react-icons/fa'; // Import the calendar icon

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${backgroundImage});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

// Updated PrimaryAction with minimized padding and flex alignment
const PrimaryAction = tw.button`
  px-4 py-2 mt-10 text-sm sm:text-base sm:mt-16 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 
  hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline 
  flex items-center
`;

// Styled component for the icon to add spacing
const Icon = tw(FaCalendarAlt)`mr-2`;

const MainComponent = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  // Define regular and primary links as arrays of objects with translation keys
  const regularLinks = [
    { label: "home", to: "#home" },
    { label: "services", to: "#services" },
    { label: "price_list", to: "#pricelist" },
    { label: "contact_us", to: "#contact" },
  ];
  
  const primaryLinks = [
    { label: "book_appointment", to: "#booking" },
    { label: "log_in", to: "/auth/sign-in" },
  ];

  // Handler to scroll to the booking section
  const handleBookAppointment = () => {
    const bookingSection = document.getElementById("booking");
    if (bookingSection) {
      bookingSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <CustomNavbar regularLinks={regularLinks} primaryLinks={primaryLinks} /> {/* Use updated props */}
        <TwoColumn>
          <LeftColumn>
            <Notification>{t('haircut_trimming_shaving')}</Notification>
            <Heading>
              <span>{t("mens_club_barbershop")}</span>
              <br />
              <SlantedBackground>{t("maikel_barber")}</SlantedBackground>
            </Heading>
            <PrimaryAction onClick={handleBookAppointment}>
              <Icon /> {/* Calendar icon */}
              {t("book_appointment")}
            </PrimaryAction>
          </LeftColumn>
          <RightColumn>
            {/* You can add content here if needed */}
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};

export default MainComponent;
