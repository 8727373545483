// chakra imports
import { Icon, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { MdUpgrade } from "react-icons/md";
import React from "react";

export function ItemContent(props) {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <>
      <Flex
        justify='center'
        align='center'
        borderRadius='4px'
        minH={{ base: "20px", md: "30px" }}
        h={{ base: "20px", md: "30px" }}
        minW={{ base: "20px", md: "30px" }}
        w={{ base: "20px", md: "30px" }}
        me='14px'
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'>
        <Icon as={MdUpgrade} color='white' w={4} h={6} />
      </Flex>
      <Flex flexDirection='column'>
        <Flex alignItems='center'>
          <Text
            fontSize={{ base: "sm", md: "sm" }}
            lineHeight='100%'
            color={textColor}>
            A customer booked an appointment
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
