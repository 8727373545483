// src/website/components/booking/serviceSelection.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  VStack,
  Radio,
  Flex,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { getStorage, ref, getDownloadURL } from "firebase/storage"; // Import Firebase Storage functions

const ServiceSelection = ({ services, selectedService, setSelectedService }) => {
  const { t } = useTranslation();
  const [servicesWithImages, setServicesWithImages] = useState([]);

  // Responsive values for padding and font size
  const paddingValue = useBreakpointValue({ base: 1, md: 1 });
  const fontSizeValue = useBreakpointValue({ base: "sm", md: "md" });

  useEffect(() => {
    const storage = getStorage(); // Initialize Firebase Storage
    const fetchServiceImages = async () => {
      const updatedServices = await Promise.all(
        services.map(async (service) => {
          if (service.profile) {
            try {
              const serviceRef = ref(storage, `services/${service.profile}`);
              const url = await getDownloadURL(serviceRef);
              return { ...service, imageUrl: url };
            } catch (error) {
              console.error(`Error fetching image for service ${service.id}:`, error);
              return { ...service, imageUrl: "/assets/img/profile/default_profile.png" };
            }
          } else {
            return { ...service, imageUrl: "/assets/img/profile/default_profile.png" };
          }
        })
      );
      setServicesWithImages(updatedServices);
    };

    fetchServiceImages();
  }, [services]);

  return (
    <Box w="100%" maxW="100%">
      <Text fontSize={{ base: "lg", md: "xl" }} mb={4}>
        {t("select_service")}
      </Text>
      <VStack spacing={2} align="stretch">
        {servicesWithImages.map((service) => (
          <Radio
            key={service.id}
            value={service.id}
            isChecked={selectedService?.id === service.id}
            onChange={() => setSelectedService(service)}
            _checked={{
              bg: "green.200",
              borderColor: "teal.500",
            }}
            borderRadius="md"
            p={0}
            width="100%" // Ensure full width on mobile screens
          >
            <Flex
              borderWidth="5px"
              borderRadius="md"
              p={paddingValue}
              align="center"
              cursor="pointer"
              _hover={{ bg: "green.100" }}
              bg={selectedService?.id === service.id ? "green.200" : "white"}
              flexDirection="row" // Always keep the image on the left
              minH="60px" // Minimum height
              height="auto" // Adjust the height dynamically based on content
            >
              {/* Service Image */}
              <Image
                src={service.imageUrl} 
                height="60px" // Fixed height for image
                width="auto"
                objectFit="cover"
                borderRadius="md"
                mr={3} // Margin for space between image and text
                fallbackSrc="/assets/img/profile/default_profile.png" // Fallback image
              />

              {/* Service Name */}
              <Text fontWeight="400" fontSize={fontSizeValue}>
                {service.service} &nbsp; {"->"} {service.price} CHF
              </Text>
            </Flex>
          </Radio>
        ))}
      </VStack>
    </Box>
  );
};

export default ServiceSelection;
