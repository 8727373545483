// src/website/components/booking/confirmBooking.js
import React from "react";
import { Box, Text, Card, SimpleGrid, VStack, HStack, Divider } from "@chakra-ui/react";
import { FaCut, FaUserTie, FaCalendarAlt, FaPhone } from "react-icons/fa";
import { format } from "date-fns"; // Ensure date-fns is installed

import { useTranslation } from 'react-i18next'; // Import useTranslation

const ConfirmBooking = ({
  selectedService,
  selectedBarber,
  selectedDate,
  selectedTime,
  personalDetails,
}) => {
  const { t } = useTranslation(); // Initialize the translation hook

  // Helper function to format date without the year
  const formatDateWithoutYear = (date) => {
    return format(date, "MMMM do"); // e.g., 'September 25th'
  };

  return (
    <Box>
      <Text fontSize="2xl" mb={4}>
        {t("confirm_your_booking")}
      </Text>
      <Card variant="outline" p={4}>
        {/* Responsive Layout: 1 column on small screens, 2 columns on medium and larger */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          
          {/* Booking Details Section */}
          <Box>
            <Text fontWeight="500" fontSize="lg" mb={2}>
              {t("booking_details")}
            </Text>
            <VStack align="start" spacing={3}>
              {/* Service */}
              <HStack spacing={3}>
                <FaCut color="teal" size={20} aria-label={t("service_icon")} />
                <Text>{t("service")}: </Text>
                <Text fontWeight="500">{t(selectedService.service)}</Text>
              </HStack>
              <Divider />
              
              {/* Barber */}
              <HStack spacing={3}>
                <FaUserTie color="teal" size={20} aria-label={t("barber_icon")} />
                <Text>{t("barber")}: </Text>
                <Text fontWeight="500">{t(selectedBarber.name)}</Text>
              </HStack>
              <Divider />
              
              {/* Date */}
              <HStack spacing={3}>
                <FaCalendarAlt color="teal" size={20} aria-label={t("date_icon")} />
                <Text>{t("date")}: </Text>
                <Text fontWeight="500">{formatDateWithoutYear(selectedDate)}</Text>
              </HStack>
              <Divider />
              
              {/* Time */}
              <HStack spacing={3}>
                <FaCalendarAlt color="teal" size={20} aria-label={t("time_icon")} />
                <Text>{t("time")}: </Text>
                <Text fontWeight="500">{selectedTime}</Text>
              </HStack>
            </VStack>
          </Box>

          {/* Personal Details Section */}
          <Box>
            <Text fontWeight="500" fontSize="lg" mb={2}>
              {t("personal_details")}
            </Text>
            <VStack align="start" spacing={3}>
              {/* Name */}
              <HStack spacing={3}>
                <FaUserTie color="teal" size={20} aria-label={t("name_icon")} />
                <Text>{t("name")}: </Text>
                <Text fontWeight="500">{personalDetails.name}</Text>
              </HStack>
              <Divider />
              
              {/* Phone */}
              <HStack spacing={3}>
                <FaPhone color="teal" size={20} aria-label={t("phone_icon")} />
                <Text>{t("phone")}: </Text>
                <Text fontWeight="500">{personalDetails.phone}</Text>
              </HStack>
            </VStack>
          </Box>
          
        </SimpleGrid>
      </Card>
    </Box>
  );
};

export default ConfirmBooking;
