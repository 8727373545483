// src/app.jsx

import './assets/css/App.css';
import { Routes, Route } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import HomePage from 'website/components/HomePage';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme'; // { themeGreen }
import { useState } from 'react';
// Import AuthProvider and PrivateRoute
import { AuthProvider } from 'views/auth/AuthContext.jsx'; // Adjust the path as necessary
import PrivateRoute from 'views/auth/PrivateRoute.jsx'; // Adjust the path as necessary

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      {/* Wrap Routes with AuthProvider */}
      <AuthProvider>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route
            path="admin/*"
            element={
              <PrivateRoute>
                <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
              </PrivateRoute>
            }
          />
          {/* Redirect root to AgencyLandingPage */}
          <Route path="/" element={<HomePage />} />
        </Routes>
      </AuthProvider>
    </ChakraProvider>
  );
}
