import {
  Flex,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/maikel-barber-w.png";
import React from "react";

export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");

  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      borderRadius='30px'
      position='relative'
      top='-40px'>
      <Flex
        border='5px solid'
        borderColor={borderColor}
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        borderRadius='50%'
        w='200px'
        h='100px'
        align='center'
        justify='center'
        mx='auto'
        position='relative'
        left='0%'
        top='0px'
        transform='translate(0%, 0%)'>
        <Image src={logoWhite} w='140px' h='70px' />
      </Flex>
      
    </Flex>
  );
}
