// src/website/components/booking/booking.js
import React, { useState, useEffect, useRef } from "react";
import {
  ChakraProvider,
  Box,
  Button,
  HStack,
  useToast,
  Heading,
  Card,
  CardBody,
  Flex,
  Text,
  Spinner,
} from "@chakra-ui/react";
import {
  FaCut,
  FaUserTie,
  FaCalendarAlt,
  FaCheckCircle,
} from "react-icons/fa";
import { format } from "date-fns";
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
import { db } from "firebaseConfig";

import ServiceSelection from "./serviceSelection";
import BarberSelection from "./barberSelection";
import DatetimeSelection from "./datetimeSelection";
import PersonalDetails from "./personalDetails";
import ConfirmBooking from "./confirmBooking";
import BookingConfirmation from "./BookingConfirmation"; // Import the new component

import { isValidPhoneNumber } from "libphonenumber-js"; // Import validation function

import { useTranslation } from 'react-i18next'; // Import useTranslation

const APPOINTMENT_INTERVAL = 40; // in minutes

const steps = [
  { title: "service", icon: FaCut },
  { title: "barber", icon: FaUserTie },
  { title: "date_time", icon: FaCalendarAlt },
  { title: "details", icon: FaUserTie },
  { title: "confirm", icon: FaCheckCircle },
];

const Booking = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [currentStep, setCurrentStep] = useState(0); // 0-based index
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceId, setServiceId] = useState(null); // Store service ID
  const [selectedBarber, setSelectedBarber] = useState(null);
  const [userId, setUserId] = useState(null); // Store barber ID
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("");
  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    phone: "", // Now includes country code via react-phone-input-2
  });
  const [loadingServices, setLoadingServices] = useState(true);
  const [errorServices, setErrorServices] = useState(null);
  const [bookingConfirmed, setBookingConfirmed] = useState(false); // New state
  const [bookingCode, setBookingCode] = useState(""); // New state
  const [bookingDetails, setBookingDetails] = useState(null); // New state to store booking details

  const toast = useToast();

  // Create a ref for the booking card
  const bookingRef = useRef(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesCollection = collection(db, "services");
        const servicesSnapshot = await getDocs(servicesCollection);
        const servicesList = servicesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(servicesList);
        setLoadingServices(false);
      } catch (error) {
        console.error("Error fetching services: ", error);
        setErrorServices("fetch_services_error");
        setLoadingServices(false);
        toast({
          title: t("error"),
          description: t("fetch_services_error"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchServices();
  }, [toast, t]);

  const scrollToBooking = () => {
    if (bookingRef.current) {
      bookingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Remove the useEffect that was scrolling on currentStep changes
  /*
  useEffect(() => {
    scrollToBooking();
  }, [currentStep]);
  */

  const handleNext = () => {
    // Validation before moving to next step
    switch (currentStep) {
      case 0:
        if (!selectedService) {
          toast({
            title: t("no_service_selected"),
            description: t("please_select_service"),
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          return;
        } else {
          setServiceId(selectedService.id); // Store service ID
        }
        break;
      case 1:
        if (!selectedBarber) {
          toast({
            title: t("no_barber_selected"),
            description: t("please_select_barber"),
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          return;
        } else {
          setUserId(selectedBarber.id); // Store barber ID
        }
        break;
      case 2:
        if (!selectedTime) {
          toast({
            title: t("no_time_selected"),
            description: t("please_select_time"),
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        break;
      case 3:
        if (!personalDetails.name || !personalDetails.phone) {
          toast({
            title: t("incomplete_details"),
            description: t("please_fill_details"),
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        // Validate phone number here
        if (!isValidPhoneNumber(personalDetails.phone)) {
          toast({
            title: t("invalid_phone_number"),
            description: t("please_enter_valid_phone"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        break;
      default:
        break;
    }
    setCurrentStep((prev) => prev + 1);
    scrollToBooking(); // Scroll after updating the step
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
    scrollToBooking(); // Scroll after updating the step
  };

  const handleConfirm = async () => {
    // Prepare date and time strings for querying
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    const formattedTime = selectedTime; // Assuming selectedTime is already in desired format (e.g., "14:30")

    // Step 1: Check if the time slot is available
    const appointmentsCollection = collection(db, "appointments");
    const qQuery = query(
      appointmentsCollection,
      where("date", "==", formattedDate),
      where("time", "==", formattedTime),
      where("userId", "==", selectedBarber.id)
    );

    try {
      const querySnapshot = await getDocs(qQuery);
      console.log(querySnapshot)

      if (!querySnapshot.empty) {
        // Time slot is already taken
        toast({
          title: t("time_slot_unavailable"),
          description: t("time_slot_unavailable_description"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });

        // Redirect user to Select Date & Time step
        setCurrentStep(2); // Assuming step 2 is Date & Time
        scrollToBooking(); // Scroll to the step after updating
        return;
      }

      // Step 2: Generate a random 3-digit booking code and prepend '0'
      const randomThreeDigit = Math.floor(100 + Math.random() * 900).toString(); // Ensures a 3-digit number
      const randomCode = `0${randomThreeDigit}`; // E.g., '0546'

      // Prepare booking data with bookingCode and price
      const bookingData = {
        serviceId,
        serviceName: selectedService.service, // Added serviceName
        userId,
        barberName: selectedBarber.name, // Added barberName
        date: formattedDate, // Includes year for database submission
        time: formattedTime,
        personalDetails: {
          name: personalDetails.name,
          phone: personalDetails.phone, // Already includes country code
        },
        bookingCode: randomCode, // Add bookingCode
        price: selectedService.price, // Add price
        createdAt: new Date(), // Optional: Timestamp for booking creation
      };

      // Step 3: Add the booking to Firestore
      await addDoc(appointmentsCollection, bookingData);

      // Step 4: Update state with booking confirmation and code
      setBookingCode(randomCode);
      setBookingDetails(bookingData); // Store booking details
      setBookingConfirmed(true);
      scrollToBooking(); // Scroll to the confirmation

      toast({
        title: t("booking_confirmed"),
        description: t("booking_success"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error confirming booking: ", error);
      toast({
        title: t("error"),
        description: t("confirm_booking_error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGoToStart = () => {
    // Reset all state variables to initial values
    setCurrentStep(0);
    setSelectedService(null);
    setServiceId(null);
    setSelectedBarber(null);
    setUserId(null);
    setSelectedDate(new Date());
    setSelectedTime("");
    setPersonalDetails({ name: "", phone: "" }); // Reset phone without countryCode
    setBookingConfirmed(false);
    setBookingCode("");
    setBookingDetails(null);
    scrollToBooking(); // Scroll back to the start
  };

  // Disable past dates in calendar
  const tileDisabledHandler = ({ date, view }) => {
    if (view === "month") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    }
    return false;
  };

  return (
    <ChakraProvider>
      <Box
        ref={bookingRef} // Assign the ref here
        p={{ base: 0, md: 5 }}
        maxW={["100%", "100%", "800px"]}
        mx="auto"
        width="100%"
        mt={{ base: 12, md: 10 }}
      >
        {/* Header */}
        <Heading as="h1" size="xl" textAlign="center" mb={6}>
          {t("book_service_online")}
        </Heading>

        {/* Stepper */}
        <Card mb={6} shadow="md" borderWidth="1px" borderRadius="md">
          <CardBody p={4}>
            <Flex justify="space-between" wrap="wrap" align="center">
              {steps.map((step, index) => (
                <Flex
                  key={index}
                  align="center"
                  flex="1"
                  minW="100px"
                  mb={[2, 0]}
                >
                  <HStack spacing={2}>
                    <Box
                      w={8}
                      h={8}
                      bg={index <= currentStep ? "teal.500" : "gray.300"}
                      color="white"
                      rounded="full"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <step.icon />
                    </Box>
                    <Text fontSize="sm" whiteSpace="nowrap">
                      {t(step.title)}
                    </Text>
                  </HStack>
                  {index < steps.length - 1 && (
                    <Box
                      flex="1"
                      h="2px"
                      bg={index < currentStep ? "teal.500" : "gray.300"}
                      mx={2}
                    ></Box>
                  )}
                </Flex>
              ))}
            </Flex>
          </CardBody>
        </Card>

        {/* Step Content or Confirmation Cards */}
        <Card shadow="md" borderWidth="1px" borderRadius="md">
          <CardBody p={4}>
            {loadingServices ? (
              <Box textAlign="center" py={10}>
                <Spinner size="xl" />
                <Text mt={4}>{t("loading_services")}</Text>
              </Box>
            ) : errorServices ? (
              <Box textAlign="center" py={10}>
                <Text color="red.500">{t(errorServices)}</Text>
              </Box>
            ) : bookingConfirmed && bookingDetails ? (
              // Confirmation View
              <Box textAlign="center" py={10}>
                
                {/* Booking Details Card */}
                <BookingConfirmation
                  bookingCode={bookingCode}
                  selectedService={selectedService}
                  selectedBarber={selectedBarber}
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                />

                {/* Button to Start Over */}
                <Button
                  mt={6}
                  onClick={handleGoToStart}
                  colorScheme="teal"
                  size="lg"
                >
                  {t("go_to_start")}
                </Button>
              </Box>
            ) : (
              // Booking Steps
              <>
                {currentStep === 0 && (
                  <ServiceSelection
                    services={services}
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                  />
                )}

                {currentStep === 1 && (
                  <BarberSelection
                    selectedBarber={selectedBarber}
                    setSelectedBarber={setSelectedBarber}
                  />
                )}

                {currentStep === 2 && (
                  <DatetimeSelection
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    appointmentInterval={APPOINTMENT_INTERVAL} // Pass the appointment interval
                    selectedBarber={selectedBarber} // Pass the selected barber
                    tileDisabled={tileDisabledHandler}
                  />
                )}

                {currentStep === 3 && (
                  <PersonalDetails
                    personalDetails={personalDetails}
                    setPersonalDetails={setPersonalDetails}
                  />
                )}

                {currentStep === 4 && (
                  <ConfirmBooking
                    selectedService={selectedService}
                    selectedBarber={selectedBarber}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    personalDetails={personalDetails}
                  />
                )}
              </>
            )}
          </CardBody>
        </Card>

        {/* Navigation Buttons */}
        {!bookingConfirmed && (
          <HStack
            mt={4}
            justify="space-between"
            gap={4}
            px={{ base: 0, md: 0 }} // Removed horizontal padding
          >
            <Button
              onClick={handleBack}
              isDisabled={currentStep === 0}
              variant="outline"
              colorScheme="teal"
              flex="1"
            >
              {t("back")}
            </Button>
            {currentStep < steps.length - 1 && (
              <Button onClick={handleNext} colorScheme="teal" flex="1">
                {t("next")}
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button onClick={handleConfirm} minW="190px" colorScheme="teal" flex="1">
                {t("confirm_booking")}
              </Button>
            )}
          </HStack>
        )}
      </Box>
    </ChakraProvider>
  );
};

export default Booking;
