// ServicesThreeColumns.js
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "website/components/misc/Headings.js";
import { SectionDescription } from "website/components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "website/components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "website/images/arrow-right-icon.svg";

import beardTrimmingIcon from "website/images/beard-trimming-icon.svg";
import shavingIcon from "website/images/shaving-icon.svg";
import haircutIcon from "website/images/haircut-icon.svg";

import { useTranslation } from 'react-i18next'; // Import useTranslation

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

export default ({
  
  cards=[
    {
      imageSrc: haircutIcon,
      title: "haircut",
      description: "haircut_description",
      url: "#haircut" // Ensure to add URL if needed
    },
    {
      imageSrc: beardTrimmingIcon,
      title: "beard_trimming",
      description: "beard_trimming_description",
      url: "#beard-trimming" // Ensure to add URL if needed
    },
    {
      imageSrc: shavingIcon,
      title: "shaving",
      description: "shaving_description",
      url: "#shaving" // Ensure to add URL if needed
    }
  ],
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This component accepts a prop - `cards` which is an array of objects denoting the cards. Each object in the cards array can have the following keys:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the translation key for the title of the card
   *  3) description - the translation key for the description of the card
   *  4) url - the url that the card should go to on click
   */
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{t(subheading)}</Subheading>}
        {heading && <Heading>{heading}</Heading>} {/* Render heading as React node */}
        {description && <Description>{t(description)}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{t(card.title)}</span>
                <p className="description">{t(card.description)}</p>
                
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
