// utils.js

import { format, parseISO } from "date-fns";
import { Timestamp } from "firebase/firestore";

/**
 * Formats a date string or Firestore Timestamp into a readable format.
 *
 * @param {string | Timestamp} dateInput - The date input to format.
 * @param {string} dateFormat - The desired date format.
 * @returns {string} - The formatted date string or "N/A" if invalid.
 */
export const formatDate = (dateInput, dateFormat = "yyyy-MM-dd HH:mm:ss") => {
  if (!dateInput || dateInput === "N/A") return "N/A";

  try {
    if (typeof dateInput === "string") {
      return format(parseISO(dateInput), dateFormat);
    } else if (dateInput instanceof Timestamp) {
      return format(dateInput.toDate(), dateFormat);
    } else if (dateInput instanceof Date) {
      return format(dateInput, dateFormat);
    } else {
      return "N/A";
    }
  } catch (error) {
    console.error("Error formatting date:", error);
    return "N/A";
  }
};
