// src/components/UsersTable.js

/* eslint-disable */

import {
  Box,
  Flex,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  IconButton,
  Tooltip,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as React from 'react';

import { useTranslation } from 'react-i18next'; // Import useTranslation
import { auth } from 'firebaseConfig'; // Import auth from your Firebase configuration

const columnHelper = createColumnHelper();

// Define columns for the users table
const columns = (t, onEdit, onDelete, currentUserId) => [
  columnHelper.accessor('name', {
    id: 'name',
    header: () => (
      <Text
        justifyContent="space-between"
        align="center"
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        color="gray.400"
        textAlign="left"
      >
        {t('users_table_name')}
      </Text>
    ),
    cell: (info) => (
      <Flex align="center">
        <Text
          color={useColorModeValue('secondaryGray.900', 'white')}
          fontSize={{ base: '12px', md: '14px', lg: '12px' }}
          fontWeight="700"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxW="120px" // Reduced max width
        >
          {info.getValue()}
        </Text>
      </Flex>
    ),
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: () => (
      <Text
        justifyContent="space-between"
        align="center"
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        color="gray.400"
        textAlign="left"
      >
        {t('users_table_email')}
      </Text>
    ),
    cell: (info) => (
      <Text
        color={useColorModeValue('secondaryGray.900', 'white')}
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        fontWeight="700"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        maxW="180px" // Reduced max width
      >
        {info.getValue()}
      </Text>
    ),
  }),
  columnHelper.accessor('phone', {
    id: 'phone',
    header: () => (
      <Text
        justifyContent="space-between"
        align="center"
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        color="gray.400"
        textAlign="left"
      >
        {t('users_table_phone')}
      </Text>
    ),
    cell: (info) => (
      <Text
        color={useColorModeValue('secondaryGray.900', 'white')}
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        fontWeight="700"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        maxW="120px" // Reduced max width
      >
        {info.getValue() || t('n_a')}
      </Text>
    ),
  }),
  // New User Type Column
  columnHelper.accessor('userType', {
    id: 'userType',
    header: () => (
      <Text
        justifyContent="space-between"
        align="center"
        fontSize={{ base: '12px', md: '14px', lg: '12px' }}
        color="gray.400"
        textAlign="left"
      >
        {t('users_table_user_type')}
      </Text>
    ),
    cell: (info) => {
      const userType = info.getValue();
      const userTypeColor = userType === "Admin" ? "blue.500" : "gray.500";

      return (
        <Text
          color={userTypeColor}
          fontSize={{ base: '12px', md: '14px', lg: '12px' }}
          fontWeight="700"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxW="100px" // Adjust max width as needed
        >
          {userType}
        </Text>
      );
    },
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: () => (
      <Flex justifyContent="space-between" align="center">
        <Text
          fontSize={{ base: '12px', md: '14px', lg: '12px' }}
          color="gray.400"
          textAlign="left"
        >
          {t('users_table_status')}
        </Text>
      </Flex>
    ),
    cell: (info) => {
      const status = info.getValue();
      const statusColor = status === "Enabled" ? "green.500" : "red.500";

      return (
        <Flex align="center" justify="space-between">
          <Text
            color={statusColor}
            fontSize={{ base: '12px', md: '14px', lg: '12px' }}
            fontWeight="700"
          >
            {status}
          </Text>
          <Flex>
            {/* Edit Icon */}
            <Tooltip label={t('users_table_edit_user_tooltip')} aria-label="Edit User Tooltip">
              <IconButton
                aria-label={t('users_table_edit_user_tooltip')}
                icon={<EditIcon />}
                size="sm"
                colorScheme="blue"
                variant="ghost"
                mr={2}
                onClick={() => onEdit(info.row.original)}
              />
            </Tooltip>
            {/* Delete Icon */}
            <Tooltip label={t('users_table_delete_user_tooltip')} aria-label="Delete User Tooltip">
              <IconButton
                aria-label={t('users_table_delete_user_tooltip')}
                icon={<DeleteIcon />}
                size="sm"
                colorScheme="red"
                variant="ghost"
                onClick={() => onDelete(info.row.original)}
                isDisabled={info.row.original.id === currentUserId} // Disable if user is the current user
              />
            </Tooltip>
          </Flex>
        </Flex>
      );
    },
  }),
];

export default function UsersTable(props) {
  const { t } = useTranslation(); // Initialize the translation hook
  const { tableData, fetching, fetchError, onEditUser, onDeleteUser } = props;
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  // Get the current user's UID
  const currentUser = auth.currentUser;
  const currentUserId = currentUser ? currentUser.uid : null;

  // Enhance table data with onEdit and onDelete callbacks
  const enhancedTableData = React.useMemo(() => {
    return tableData.map(user => ({
      ...user,
      onEdit: onEditUser, // Assign the edit callback function
      onDelete: onDeleteUser, // Assign the delete callback function
    }));
  }, [tableData, onEditUser, onDeleteUser]);

  const table = useReactTable({
    data: enhancedTableData,
    columns: columns(t, onEditUser, onDeleteUser, currentUserId), // Pass the currentUserId to columns
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ base: 'auto', lg: 'hidden' }} // Allow horizontal scroll on small screens
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize={{ base: '20px', md: '22px' }}
          fontWeight="700"
          lineHeight="100%"
        >
          {t('users_table_header_user_accounts')}
        </Text>
      </Flex>

      {/* Display Loading Spinner */}
      {fetching && (
        <Flex justify="center" align="center" mt="20px" mb="20px">
          <Spinner size="lg" color="blue.500" />
        </Flex>
      )}

      {/* Display Fetch Error */}
      {fetchError && (
        <Alert status="error" mb="20px">
          <AlertIcon />
          {fetchError}
        </Alert>
      )}

      {/* Display Table Only If Data is Available and Not Fetching */}
      {!fetching && !fetchError && (
        <Box overflowX="auto">
          <Table
            variant="simple"
            color="gray.500"
            mb="24px"
            mt="12px"
            minW="100%" // Ensure table takes full width
            tableLayout="fixed" // Fix table layout for consistent column widths
          >
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                        paddingY="8px" // Reduced vertical padding
                        paddingX="12px" // Reduced horizontal padding
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ base: '10px', md: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ base: '12px', md: '14px', lg: '12px' }}
                          paddingY="6px" // Reduced vertical padding
                          paddingX="12px" // Reduced horizontal padding
                          borderColor="transparent"
                          overflow="hidden" // Ensure content doesn't overflow
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </Card>
  );
}
