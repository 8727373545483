// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Initialize i18next
i18n
  .use(Backend) // Loads translations from /public/locales/{{lng}}/translation.json
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    // Set the initial language to French
    lng: 'fr',

    // Set fallback language to French
    fallbackLng: 'fr',

    debug: false, // Set to true for debugging

    interpolation: {
      escapeValue: false, // React already protects from XSS
    },

    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path to translation files
    },

    supportedLngs: ['en', 'fr'], // List of supported languages

    detection: {
      // Order and from where user language should be detected
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],

      // Keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',

      // Cache user language on
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
