// src/views/admin/default/components/navbar.js

import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { FaCalendarAlt, FaUser } from 'react-icons/fa'; // Removed FaGlobe
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import ReactCountryFlag from "react-country-flag"; // Imported ReactCountryFlag

// 1. Navbar container with justify-between to space Logo and MenuLinks
const Nav = tw.nav`flex items-center justify-between flex-wrap bg-transparent p-10`;

// 2. Logo styling remains unchanged
const Logo = styled(RouterLink)`
  ${tw`flex items-center flex-shrink-0 text-gray-100`}
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    ${tw`text-gray-300`}
  }
`;

// 3. Menu toggle icon remains unchanged
const MenuIcon = tw.div`block lg:hidden cursor-pointer text-gray-100`;

// 4. Modified MenuLinks to handle collapsible behavior
const MenuLinks = styled.div`
  ${tw`w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? "1000px" : "0")}; /* Smooth animation */

  /* For large screens, reset max-height */
  @media (min-width: 1024px) {
    max-height: none;
    ${tw`flex`}
  }
`;

// 5. Flex container to arrange all links with ml-auto to push to the right,
//    and responsive spacing using space-y and space-x
const LinksContainer = tw.div`flex flex-col lg:flex-row w-full lg:w-auto ml-auto space-y-4 lg:space-y-0 lg:space-x-8`;

// 6. Regular link styled component (for route-based links)
const MenuLink = styled(RouterLink)`
  ${tw`block lg:inline-block text-gray-100 hover:text-gray-300 cursor-pointer`}
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
`;

// 7. Primary link styled component (excluding "Book Appointment")
const PrimaryMenuLink = styled(RouterLink)`
  ${tw`inline-block text-sm px-4 py-2 leading-none border rounded text-primary-500 border-primary-500 hover:border-transparent hover:text-gray-100 hover:bg-primary-500 transition-colors duration-300 cursor-pointer`}
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
`;

// 8. Appointment Button styled component with Calendar Icon (modified to use 'a' tag)
const AppointmentButton = styled.a`
  ${tw`inline-flex items-center text-sm px-4 py-2 leading-none border rounded text-purple-600 bg-white border-purple-600 hover:bg-purple-600 hover:text-white transition-colors duration-300`}
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
`;

// 10. Login Button styled component with Login Icon
const LoginButton = styled(RouterLink)`
  ${tw`inline-flex items-center text-sm px-4 py-2 leading-none border rounded text-blue-600 bg-white border-blue-600 hover:bg-blue-600 hover:text-white transition-colors duration-300`}
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
`;

// 9. AnchorLink styled component for in-page scrolling
const AnchorLink = styled.a`
  ${tw`block lg:inline-block text-gray-100 hover:text-gray-300 cursor-pointer`}
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
`;

// Icon for Appointment Button
const Iconn = tw(FaCalendarAlt)`mr-2`;

// Icon for Login Button
const LoginIcon = tw(FaUser)`mr-2`; // Added for login button

const CustomNavbar = ({ regularLinks, primaryLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // To handle active link styling if needed
  const { t, i18n } = useTranslation(); // Hook for translation

  const navbarIcon = useColorModeValue('blue.300', 'white'); // Moved inside the component

  // Function to toggle the menu on mobile
  const toggleMenu = () => setIsOpen(!isOpen);

  // Function to check if current path matches link's route
  const isActive = (linkPath) => location.pathname === linkPath;

  // Handle language change
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  // Determine the current country code based on the selected language
  const getCurrentCountryCode = () => {
    switch (i18n.language) {
      case 'fr':
        return 'CH';
      case 'en':
      default:
        return 'GB';
    }
  };

  return (
    <Nav>
      <Logo to="/">
        {/* Replace with your logo image if available */}
        {t('maikel_barber')}
      </Logo>

      {/* Language Selection Menu */}
      <Menu>
        <MenuButton ml="10px" aria-label={t('language')}>
          <ReactCountryFlag
            countryCode={getCurrentCountryCode()}
            svg
            style={{
              width: '1.5em',
              height: '1.5em',
            }}
            title={i18n.language === 'fr' ? 'French' : 'English'}
          />
        </MenuButton>
        <MenuList
          boxShadow="md"
          p="10px"
          borderRadius="20px"
          bg={useColorModeValue('white', 'gray.700')}
          border="none"
          mt="10px"
          minW="120px"
        >
          <MenuItem
            onClick={() => handleLanguageChange('fr')}
            _hover={{ bg: 'teal.100' }}
            display="flex"
            alignItems="center"
          >
            <ReactCountryFlag
              countryCode="CH"
              svg
              style={{ marginRight: '8px', width: '1.5em', height: '1.5em' }}
              title="French"
            />
            {t('french')}
          </MenuItem>
          <MenuItem
            onClick={() => handleLanguageChange('en')}
            _hover={{ bg: 'teal.100' }}
            display="flex"
            alignItems="center"
          >
            <ReactCountryFlag
              countryCode="GB"
              svg
              style={{ marginRight: '8px', width: '1.5em', height: '1.5em' }}
              title="English"
            />
            {t('english')}
          </MenuItem>
          
        </MenuList>
      </Menu>

      {/* Remove the LanguageSelector */}
      {/* <LanguageSelector value={i18n.language} onChange={handleLanguageChange} aria-label={t('select_language')}>
        <option value="fr">FR</option>
        <option value="en">EN</option>
      </LanguageSelector> */}

      <MenuIcon onClick={toggleMenu} aria-label="Toggle navigation menu">
        {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
      </MenuIcon>
      <MenuLinks isOpen={isOpen}>
        <LinksContainer>
          {/* Render Regular Links */}
          {regularLinks.map((link, index) => {
            // Determine if the link is an anchor link (has href with hash)
            const isAnchorLink = link.to.startsWith("#");
            return isAnchorLink ? (
              <AnchorLink
                key={index}
                href={link.to}
                onClick={() => setIsOpen(false)} // Close menu on link click
              >
                {t(link.label)}
              </AnchorLink>
            ) : (
              <MenuLink
                key={index}
                to={link.to}
                onClick={() => setIsOpen(false)} // Close menu on link click
                aria-current={isActive(link.to) ? "page" : undefined}
              >
                {t(link.label)}
              </MenuLink>
            );
          })}
          {/* Render Primary Links */}
          {primaryLinks.map((link, index) => (
            link.label === "book_appointment" ? ( // Use translation key here
              <AppointmentButton
                key={index}
                href={link.to} // Use href instead of to
                onClick={() => setIsOpen(false)} // Close menu on link click
                aria-current={isActive(link.to) ? "page" : undefined}
              >
                <Iconn /> {/* Corrected margin */}
                {t(link.label)}
              </AppointmentButton>
            ) : link.label === "log_in" ? (
              <LoginButton
                key={index}
                to={link.to} // Use RouterLink for navigation
                onClick={() => setIsOpen(false)} // Close menu on link click
                aria-current={isActive(link.to) ? "page" : undefined}
              >
                <LoginIcon /> {/* Icon for login */}
                {t(link.label)}
              </LoginButton>
            ) : (
              // Determine if the primary link is an anchor link
              link.to.startsWith("#") ? (
                <AnchorLink
                  key={index}
                  href={link.to}
                  onClick={() => setIsOpen(false)} // Close menu on link click
                >
                  {t(link.label)}
                </AnchorLink>
              ) : (
                <PrimaryMenuLink
                  key={index}
                  to={link.to}
                  onClick={() => setIsOpen(false)} // Close menu on link click
                  aria-current={isActive(link.to) ? "page" : undefined}
                >
                  {t(link.label)}
                </PrimaryMenuLink>
              )
            )
          ))}
        </LinksContainer>
      </MenuLinks>
    </Nav>
  );
};

export default CustomNavbar;
