// src/views/admin/profile/ImageGallery.jsx

// Chakra UI Imports
import {
  Image,
  Box,
  Text,
  useColorModeValue,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  SimpleGrid,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import React, { useState, useEffect, useRef } from "react";

// Firebase Imports
import { storage, db } from "firebaseConfig"; // Adjust the path as necessary
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";

// Card Component (Assuming it's a custom component you have)
import Card from "components/card/Card.js";

// Translation hook
import { useTranslation } from "react-i18next";

// Authentication context (if needed for admin actions)
import { useAuth } from "../../auth/AuthContext.jsx";

export default function ImageGallery() {
  const { t } = useTranslation(); // Initialize the translation hook
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useAuth(); // If admin authentication is required

  // State Management
  const [images, setImages] = useState([]); // Array of { id, filename, url }
  const [loadingImages, setLoadingImages] = useState(true); // Loading state for fetching images
  const [uploading, setUploading] = useState(false); // Uploading state
  const [selectedFile, setSelectedFile] = useState(null); // Selected file for upload
  const [currentEditImage, setCurrentEditImage] = useState(null); // Image object being edited
  const [imageToDelete, setImageToDelete] = useState(null); // Image object to delete

  // Define color mode values at the top level (Unconditional Hook Calls)
  const uploadHoverBg = useColorModeValue("gray.100", "gray.700");
  const uploadBorderColor = useColorModeValue("gray.200", "gray.700");
  const imageTextColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const uploadTextColor = useColorModeValue("gray.800", "whiteAlpha.900");

  const maxImageUpload = 8; // Set the maximum number of images allowed

  // Refs for file inputs
  const uploadInputRef = useRef(null);
  const updateInputRef = useRef(null);

  // Fetch Gallery Images on Component Mount and Listen for Real-Time Updates
  useEffect(() => {
    const galleryCollectionRef = collection(db, "gallery");

    const unsubscribe = onSnapshot(
      galleryCollectionRef,
      async (snapshot) => {
        try {
          // Use Promise.all to fetch all download URLs in parallel
          const fetchedImages = await Promise.all(
            snapshot.docs.map(async (docSnap) => {
              const data = docSnap.data();
              const imageRef = ref(storage, `gallery/${data.filename}`);
              try {
                const url = await getDownloadURL(imageRef);
                return {
                  id: docSnap.id,
                  filename: data.filename,
                  url,
                };
              } catch (error) {
                console.error("Error fetching image URL:", error);
                return null; // Exclude images that failed to load
              }
            })
          );

          // Filter out any null entries due to failed URL fetches
          const validImages = fetchedImages.filter((img) => img !== null);

          setImages(validImages);
        } catch (error) {
          console.error("Error processing gallery images:", error);
          toast({
            title: t("image_gallery_fetch_error"),
            description: t("image_gallery_unable_fetch"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setLoadingImages(false);
        }
      },
      (error) => {
        console.error("Error fetching gallery images:", error);
        toast({
          title: t("image_gallery_fetch_error"),
          description: t("image_gallery_unable_fetch"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoadingImages(false);
      }
    );

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [toast, t]);

  // Handle File Selection for Upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        toast({
          title: t("invalid_file_type_title"),
          description: t("invalid_file_type_description"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Validate file size (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        toast({
          title: t("file_size_exceeded_title"),
          description: t("file_size_exceeded_description"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setSelectedFile(file);
    }
  };

  // Handle File Selection for Updating an Image
  const handleUpdateFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        toast({
          title: t("invalid_file_type_title"),
          description: t("invalid_file_type_description"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Validate file size (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        toast({
          title: t("file_size_exceeded_title"),
          description: t("file_size_exceeded_description"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setSelectedFile(file);
    }
  };

  // Handle Uploading a New Image
  const handleUpload = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      toast({
        title: t("image_gallery_no_file_selected_title"),
        description: t("image_gallery_no_file_selected_description"),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (images.length >= maxImageUpload) {
      toast({
        title: t("image_gallery_max_limit_title"),
        description: t("image_gallery_max_limit_description", { max: maxImageUpload }),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setUploading(true); // Disable the upload button and show spinner

    try {
      const timestamp = Date.now();
      const sanitizedFileName = selectedFile.name.replace(/[^a-zA-Z0-9.\-_]/g, ""); // Sanitize file name
      const fileName = `image-${timestamp}-${sanitizedFileName}`;
      const storageRefPath = `gallery/${fileName}`;
      const storageRefObj = ref(storage, storageRefPath);

      // Upload the file
      const uploadTask = uploadBytesResumable(storageRefObj, selectedFile);

      // Monitor upload progress and handle completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Optional: Implement a progress bar here
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Upload failed:", error);
          toast({
            title: t("image_gallery_upload_failed"),
            description: error.message || t("image_gallery_error_upload"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setUploading(false); // Re-enable the upload button
        },
        async () => {
          // Upload completed successfully, now get the download URL
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("File available at", downloadURL);

            // Add image metadata to Firestore
            const galleryCollectionRef = collection(db, "gallery");
            await addDoc(galleryCollectionRef, {
              filename: fileName,
              createdAt: serverTimestamp(),
            });

            toast({
              title: t("image_gallery_uploaded_title"),
              description: t("image_gallery_uploaded_description"),
              status: "success",
              duration: 5000,
              isClosable: true,
            });

            // Reset the selected file
            setSelectedFile(null);

            // Reset the file input value to allow re-uploading the same file
            if (uploadInputRef.current) {
              uploadInputRef.current.value = null;
            }
          } catch (error) {
            console.error("Error getting download URL or updating Firestore:", error);
            toast({
              title: t("image_gallery_upload_failed"),
              description: error.message || t("image_gallery_error_upload"),
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } finally {
            setUploading(false); // Re-enable the upload button
          }
        }
      );
    } catch (error) {
      console.error("Error uploading image:", error);
      toast({
        title: t("image_gallery_upload_failed"),
        description: error.message || t("image_gallery_error_upload"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setUploading(false); // Re-enable the upload button
    }
  };

  // Handle Deleting an Image
  const handleDelete = async () => {
    if (!imageToDelete) return;

    setUploading(true); // Disable the delete button and show spinner if applicable

    try {
      // Delete the image from Firebase Storage
      const imageRef = ref(storage, `gallery/${imageToDelete.filename}`);
      await deleteObject(imageRef);
      console.log("Image deleted from storage.");

      // Delete the image document from Firestore
      const imageDocRef = doc(db, "gallery", imageToDelete.id);
      await deleteDoc(imageDocRef);
      console.log("Image document deleted from Firestore.");

      toast({
        title: t("image_gallery_deleted_title"),
        description: t("image_gallery_deleted_description"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset imageToDelete
      setImageToDelete(null);
    } catch (error) {
      console.error("Error deleting image:", error);
      toast({
        title: t("image_gallery_delete_failed"),
        description: error.message || t("image_gallery_error_delete"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setUploading(false); // Re-enable the delete button
      onClose(); // Close the modal
    }
  };

  // Handle Updating an Existing Image
  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!selectedFile || !currentEditImage) {
      toast({
        title: t("image_gallery_no_file_selected_title"),
        description: t("image_gallery_no_file_selected_description"),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setUploading(true); // Disable the update button and show spinner

    try {
      const oldFilename = currentEditImage.filename;
      const oldImageRef = ref(storage, `gallery/${oldFilename}`);

      // Delete the old image from Storage
      await deleteObject(oldImageRef);
      console.log("Old image deleted from storage.");

      // Upload the new image
      const timestamp = Date.now();
      const sanitizedFileName = selectedFile.name.replace(/[^a-zA-Z0-9.\-_]/g, ""); // Sanitize file name
      const newFileName = `image-${timestamp}-${sanitizedFileName}`;
      const newStorageRefPath = `gallery/${newFileName}`;
      const newStorageRef = ref(storage, newStorageRefPath);

      const uploadTask = uploadBytesResumable(newStorageRef, selectedFile);

      // Monitor upload progress and handle completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Optional: Implement a progress bar here
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Upload failed:", error);
          toast({
            title: t("image_gallery_update_failed"),
            description: error.message || t("image_gallery_error_upload"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setUploading(false); // Re-enable the update button
        },
        async () => {
          // Upload completed successfully, now get the download URL
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("File available at", downloadURL);

            // Update the Firestore document with the new filename
            const imageDocRef = doc(db, "gallery", currentEditImage.id);
            await updateDoc(imageDocRef, {
              filename: newFileName,
              updatedAt: serverTimestamp(),
            });

            toast({
              title: t("image_gallery_updated_title"),
              description: t("image_gallery_updated_description"),
              status: "success",
              duration: 5000,
              isClosable: true,
            });

            // Reset states and close modal
            setSelectedFile(null);
            setCurrentEditImage(null);
            onClose();

            // Reset the file input value to allow re-uploading the same file
            if (updateInputRef.current) {
              updateInputRef.current.value = null;
            }
          } catch (error) {
            console.error("Error getting download URL or updating Firestore:", error);
            toast({
              title: t("image_gallery_update_failed"),
              description: error.message || t("image_gallery_error_upload"),
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } finally {
            setUploading(false); // Re-enable the update button
          }
        }
      );
    } catch (error) {
      console.error("Error updating image:", error);
      toast({
        title: t("image_gallery_update_failed"),
        description: error.message || t("image_gallery_error_upload"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setUploading(false); // Re-enable the update button
    }
  };

  // Open Update Modal with Selected Image
  const openUpdateModal = (image) => {
    setCurrentEditImage(image);
    setSelectedFile(null);
    onOpen();
  };

  // Open Delete Confirmation Modal
  const openDeleteModal = (image) => {
    setImageToDelete(image);
    onOpen();
  };

  // Handle Modal Close and Reset States
  const handleModalClose = () => {
    setSelectedFile(null);
    setCurrentEditImage(null);
    setImageToDelete(null);
    onClose();

    // Reset the file inputs to allow re-selection
    if (uploadInputRef.current) {
      uploadInputRef.current.value = null;
    }
    if (updateInputRef.current) {
      updateInputRef.current.value = null;
    }
  };

  return (
    <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
      <Card mb={{ base: "0px", lg: "20px" }} align="center" w="100%">
        <Text color={imageTextColor} fontWeight="bold" fontSize="2xl" mb="6">
          {t("image_gallery_title")}
        </Text>

        {/* Gallery Display */}
        {loadingImages ? (
          <Spinner size="xl" />
        ) : (
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
            spacing={6}
            w="100%"
            mb="6"
          >
            {images.map((image) => (
              <Box
                key={image.id}
                position="relative"
                overflow="hidden"
                borderRadius="md"
              >
                <Image
                  src={image.url}
                  alt={`${t("image_gallery_image_alt")} ${image.id}`}
                  objectFit="cover"
                  width="100%"
                  height={{ base: "200px", md: "250px" }}
                  transition="transform 0.3s ease"
                  _hover={{ transform: "scale(1.05)" }}
                  loading="lazy"
                />
                {/* Edit Button */}
                <IconButton
                  icon={<EditIcon />}
                  size="sm"
                  colorScheme="teal"
                  position="absolute"
                  top="2"
                  right="2"
                  onClick={() => openUpdateModal(image)}
                  aria-label={t("image_gallery_update_image_tooltip")}
                  opacity="0.8"
                  _hover={{ opacity: "1" }}
                  isDisabled={uploading} // Disable during uploading
                />
                {/* Delete Button */}
                <IconButton
                  icon={<DeleteIcon />}
                  size="sm"
                  colorScheme="red"
                  position="absolute"
                  bottom="2"
                  right="2"
                  onClick={() => openDeleteModal(image)}
                  aria-label={t("image_gallery_delete_image_tooltip")}
                  opacity="0.8"
                  _hover={{ opacity: "1" }}
                  isDisabled={uploading} // Disable during uploading
                />
              </Box>
            ))}

            {/* Upload Button (if less than max images) */}
            {images.length < maxImageUpload && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="2px dashed"
                borderColor={uploadBorderColor}
                borderRadius="md"
                height={{ base: "200px", md: "250px" }}
                cursor="pointer"
                onClick={() => {
                  if (uploadInputRef.current) {
                    uploadInputRef.current.click();
                  }
                }}
                position="relative"
                transition="background-color 0.3s ease"
                _hover={{ backgroundColor: uploadHoverBg }}
              >
                <Text color={uploadTextColor} fontSize="lg">
                  {t("image_gallery_add_image")}
                </Text>
                <Input
                  type="file"
                  id="uploadInput"
                  accept="image/*"
                  display="none"
                  onChange={handleFileChange}
                  ref={uploadInputRef} // Assign ref
                  disabled={uploading} // Disable input during uploading
                />
              </Box>
            )}
          </SimpleGrid>
        )}

        {/* Upload Form */}
        {selectedFile && images.length < maxImageUpload && (
          <Box mt="4" w="80%" maxW="500px" mx="auto">
            <form onSubmit={handleUpload}>
              <FormControl isRequired>
                <FormLabel>
                  {t("image_gallery_selected_file_label")}: {selectedFile.name}
                </FormLabel>
                <Image
                  src={URL.createObjectURL(selectedFile)}
                  alt={t("image_gallery_selected_image_alt")}
                  objectFit="cover"
                  width="100%"
                  height={{ base: "200px", md: "250px" }}
                  borderRadius="md"
                  boxShadow="sm"
                  mb="4"
                />
              </FormControl>
              <Button
                mt={2}
                colorScheme="teal"
                isLoading={uploading}
                type="submit"
                isDisabled={uploading} // Disable the button while uploading
                w="100%"
              >
                {t("image_gallery_upload_button")}
              </Button>
            </form>
          </Box>
        )}

        {/* Update Modal */}
        <Modal isOpen={isOpen && currentEditImage} onClose={handleModalClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("image_gallery_update_modal_header")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired>
                <FormLabel>{t("image_gallery_select_new_image")}</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleUpdateFileChange}
                  ref={updateInputRef} // Assign ref
                  isDisabled={uploading} // Disable input during uploading
                />
                {selectedFile && (
                  <Image
                    src={URL.createObjectURL(selectedFile)}
                    alt={t("image_gallery_new_image_preview_alt")}
                    objectFit="cover"
                    width="100%"
                    height={{ base: "200px", md: "250px" }}
                    borderRadius="md"
                    boxShadow="sm"
                    mt="4"
                  />
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={handleModalClose}
                isDisabled={uploading} // Disable during uploading
              >
                {t("image_gallery_cancel_button")}
              </Button>
              <Button
                colorScheme="teal"
                isLoading={uploading}
                onClick={handleUpdate}
                isDisabled={!selectedFile || uploading} // Disable while uploading or if no file is selected
              >
                {t("image_gallery_update_button")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal isOpen={isOpen && imageToDelete} onClose={handleModalClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("image_gallery_delete_modal_header")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>{t("image_gallery_delete_confirmation_message")}</Text>
              {imageToDelete && (
                <Image
                  src={imageToDelete.url}
                  alt={`${t("image_gallery_image_alt")} ${imageToDelete.id}`}
                  objectFit="cover"
                  width="100%"
                  height={{ base: "200px", md: "250px" }}
                  borderRadius="md"
                  boxShadow="sm"
                  mt="4"
                />
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={handleModalClose}
                isDisabled={uploading} // Disable during uploading
              >
                {t("image_gallery_cancel_button")}
              </Button>
              <Button
                colorScheme="red"
                isLoading={uploading}
                onClick={handleDelete}
                isDisabled={uploading} // Disable while deleting
              >
                {t("image_gallery_delete_button")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Card>
    </Box>
  );
}
