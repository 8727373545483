// src/website/components/booking/datetimeSelection.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  SimpleGrid,
  Button,
  Spinner,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "firebaseConfig";
import { format, isSameDay, addMinutes } from "date-fns";
import { toZonedTime } from "date-fns-tz"; // Use toZonedTime instead of utcToZonedTime

import { useTranslation } from 'react-i18next'; // Import useTranslation

const APPOINTMENT_INTERVAL = 30; // in minutes
const SLOT_START_TIME = "08:00";
const SLOT_END_TIME = "20:00";
const SWITZERLAND_TIMEZONE = "Europe/Zurich"; // Define Switzerland timezone

const DatetimeSelection = ({
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  appointmentInterval, // in minutes
  selectedBarber, // object containing barber details
  tileDisabled,
}) => {
  const { t, i18n } = useTranslation(); // Initialize the translation hook and get current language
  const [bookedAppointments, setBookedAppointments] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const toast = useToast();

  // Determine locale based on current language
  const getCalendarLocale = () => {
    const language = i18n.language;
    const localeMap = {
      en: 'en-US',
      fr: 'fr-FR',
      // Add more mappings if you support additional languages
    };
    return localeMap[language] || 'en-US'; // Default to 'en-US' if language not mapped
  };

  // Calculate current time in Switzerland and check if selected date is today in Switzerland
  const nowInSwitzerland = toZonedTime(new Date(), SWITZERLAND_TIMEZONE);
  const isTodayInSwitzerland = isSameDay(selectedDate, nowInSwitzerland);
  const currentTimeInSwitzerland = isTodayInSwitzerland
    ? nowInSwitzerland
    : null;

  // Function to fetch booked appointments
  const fetchBookedAppointments = async (barberId, date) => {
    try {
      const appointmentsRef = collection(db, "appointments");
      const q = query(
        appointmentsRef,
        where("userId", "==", barberId),
        where("date", "==", formatDateKey(date))
      );
      const querySnapshot = await getDocs(q);
      const bookings = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBookedAppointments(bookings);
    } catch (err) {
      console.error("Error fetching appointments: ", err);
      setError("fetch_appointments_error"); // Use key instead of translation function
      toast({
        title: t("error"),
        description: t("fetch_appointments_error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to fetch working hours
  const fetchWorkingHours = async (barberId, date) => {
    try {
      const workingHoursDocRef = doc(db, "working_hours", barberId);
      const workingHoursSnap = await getDoc(workingHoursDocRef);
      if (!workingHoursSnap.exists()) {
        setWorkingHours([]);
        return;
      }
      const docData = workingHoursSnap.data();
      const dayOfWeek = getDayOfWeek(date); // e.g., 'Monday'
      const hours = docData[dayOfWeek] || [];
      setWorkingHours(hours);
    } catch (err) {
      console.error("Error fetching working hours: ", err);
      setError("fetch_working_hours_error"); // Use key instead of translation function
      toast({
        title: t("error"),
        description: t("fetch_working_hours_error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Helper function to format date as 'YYYY-MM-DD'
  const formatDateKey = (date) => {
    return format(date, "yyyy-MM-dd"); // Using date-fns for formatting
  };

  // Helper function to get day of the week
  const getDayOfWeek = (date) => {
    return format(date, "EEEE"); // e.g., 'Monday'
  };

  // Function to generate all time slots between SLOT_START_TIME and SLOT_END_TIME
  const generateAllTimeSlots = () => {
    const slots = [];
    const [startHour, startMinute] = SLOT_START_TIME.split(":").map(Number);
    const [endHour, endMinute] = SLOT_END_TIME.split(":").map(Number);

    let current = new Date(selectedDate);
    current.setHours(startHour, startMinute, 0, 0);

    const end = new Date(selectedDate);
    end.setHours(endHour, endMinute, 0, 0);

    while (current < end) {
      const slotLabel = formatTime(current);
      slots.push(slotLabel);
      current = addMinutes(current, appointmentInterval); // Increment by appointment interval
    }

    return slots;
  };

  // Function to generate available and disabled time slots
  const generateTimeSlots = () => {
    const allSlots = generateAllTimeSlots();

    const slotsWithStatus = allSlots.map((slot) => {
      const slotTime = parseTime(slot);

      // Check if slot falls within any working interval
      const isWithinWorkingHours = workingHours.some((interval) => {
        const intervalStart = parseTime(interval.start);
        const intervalEnd = parseTime(interval.end);
        return slotTime >= intervalStart && slotTime < intervalEnd;
      });

      // Check if slot is already booked
      const isBooked = bookedAppointments.some(
        (appointment) => appointment.time === slot
      );

      // If selected date is today, disable slots before current time
      let isBeforeCurrentTime = false;
      if (isTodayInSwitzerland && currentTimeInSwitzerland) {
        isBeforeCurrentTime = slotTime < currentTimeInSwitzerland;
      }

      return {
        time: slot,
        isAvailable: isWithinWorkingHours && !isBooked && !isBeforeCurrentTime,
      };
    });

    setAvailableTimeSlots(slotsWithStatus);
  };

  // Helper function to parse time string 'HH:mm' into Date object on selected date
  const parseTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const date = new Date(selectedDate);
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  // Helper function to format Date object into 'HH:mm'
  const formatTime = (date) => {
    const hours = (`0${date.getHours()}`).slice(-2);
    const minutes = (`0${date.getMinutes()}`).slice(-2);
    return `${hours}:${minutes}`;
  };

  // Effect to fetch data whenever selectedDate or selectedBarber changes
  useEffect(() => {
    if (!selectedBarber) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      await Promise.all([
        fetchBookedAppointments(selectedBarber.id, selectedDate),
        fetchWorkingHours(selectedBarber.id, selectedDate),
      ]);
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBarber, selectedDate]);

  // Effect to generate time slots whenever workingHours or bookedAppointments change
  useEffect(() => {
    if (!selectedBarber) {
      setAvailableTimeSlots([]);
      return;
    }

    generateTimeSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingHours, bookedAppointments]);

  // Effect to reset selectedTime if it's no longer available
  useEffect(() => {
    if (!selectedTime) return;

    const isSelectedTimeAvailable = availableTimeSlots.some(
      (slot) => slot.time === selectedTime && slot.isAvailable
    );

    if (!isSelectedTimeAvailable) {
      setSelectedTime("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTimeSlots, selectedTime]);

  if (!selectedBarber) {
    return (
      <Box textAlign="center" py={10}>
        <Text>{t("please_select_barber_first")}</Text>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
        <Text mt={4}>{t("loading_appointments")}</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">{t(error)}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text fontSize="xl" mb={4}>
        {t("select_date_time")}
      </Text>
      <Flex direction={["column", "row"]} gap={6}>
        <Box flex="1">
          <Calendar
            onChange={setSelectedDate}
            value={selectedDate}
            tileDisabled={tileDisabled}
            locale={getCalendarLocale()} // Set locale based on current language
          />
        </Box>
        <Box flex="1">
          <Text mb={2}>{t("available_time_slots")}</Text>
          {availableTimeSlots.length === 0 ? (
            <Text>{t("no_working_hours")}</Text>
          ) : (
            <SimpleGrid columns={[3, 4, 5]} spacing={2}>
              {availableTimeSlots.map(({ time, isAvailable }) => (
                <Tooltip
                  key={time}
                  label={
                    isAvailable
                      ? ""
                      : isTodayInSwitzerland
                      ? t("time_slot_passed")
                      : t("time_slot_unavailable")
                  }
                  isDisabled={isAvailable}
                >
                  <Button
                    size="sm"
                    variant="outline"
                    color={selectedTime === time ? "white" : "black"} // Font color
                    borderColor="black" // Border color
                    bg={selectedTime === time ? "green.600" : "white"} // Background color when selected
                    _hover={{
                      bg: selectedTime === time ? "green.600" : "gray.100",
                    }}
                    onClick={() => {
                      if (isAvailable) {
                        setSelectedTime(time);
                      }
                    }}
                    isDisabled={!isAvailable}
                    fontSize={["xs", "sm"]} // Responsive font size
                    px={[2, 4]} // Responsive padding x-axis
                  >
                    {time}
                  </Button>
                </Tooltip>
              ))}
            </SimpleGrid>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default DatetimeSelection;
