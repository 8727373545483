// src/components/Services.jsx 

// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
  Spinner,
  Input,
  Alert,
  AlertIcon,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import PricesTable from "./components/ServicesTable"; // Adjust the import path as necessary
import Card from "components/card/Card.js";
import { HSeparator } from "components/separator/Separator";

// Firebase imports
import { db, storage } from "firebaseConfig"; // Adjust the path as necessary
import { addDoc, collection, getDocs, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

// Icons (optional)
import { FcGoogle } from "react-icons/fc"; // Example icon

// Firebase Authentication (Assuming Firebase is initialized)
import { getAuth } from "firebase/auth";

import { useTranslation } from 'react-i18next'; // Import useTranslation

export default function Services() {
  const { t } = useTranslation(); // Initialize the translation hook

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  // State management for form fields and feedback
  const [service, setService] = useState("");
  const [price, setPrice] = useState("");
  const [serviceProfile, setServiceProfile] = useState(null); // For storing the selected image file
  const [profilePreview, setProfilePreview] = useState(null); // For previewing the selected image
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  // State for services data
  const [services, setServices] = useState([]);
  const [fetchingServices, setFetchingServices] = useState(true);
  const [fetchError, setFetchError] = useState("");

  // State to manage edit mode
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [existingProfile, setExistingProfile] = useState(null); // To keep track of existing profile image
  const [existingProfileUrl, setExistingProfileUrl] = useState(null); // To keep track of existing profile image URL

  // Initialize Firebase Auth
  const auth = getAuth();

  // Chakra UI toast for notifications
  const toast = useToast();

  // Fetch services from Firestore on component mount
  useEffect(() => {
    const fetchServices = async () => {
      setFetchingServices(true);
      setFetchError("");

      try {
        const serviceCollection = collection(db, "services");
        const servicesSnapshot = await getDocs(serviceCollection);
        const servicesList = await Promise.all(
          servicesSnapshot.docs.map(async (docSnap) => {
            const serviceData = {
              id: docSnap.id,
              ...docSnap.data(),
            };

            if (serviceData.profile) {
              try {
                const profileRef = ref(storage, `services/${serviceData.profile}`);
                const downloadURL = await getDownloadURL(profileRef);
                serviceData.profileUrl = downloadURL;
              } catch (error) {
                console.error(`Error fetching profile image for service ${serviceData.id}:`, error);
                serviceData.profileUrl = null; // Or set to a default image URL if preferred
              }
            }

            return serviceData;
          })
        );

        setServices(servicesList);
      } catch (err) {
        console.error("Error fetching services:", err);
        setFetchError(t("services_fetch_error"));
      } finally {
        setFetchingServices(false);
      }
    };

    fetchServices();
  }, [t]);

  // Handle form submission to add or update a service
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // Basic validation
    if (!service || !price || (!isEditMode && !serviceProfile)) {
      toast({
        title: t("services_missing_info_title"),
        description: t("services_missing_info_description"),
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    console.log(`${isEditMode ? t("services_updating_service") : t("services_adding_service")}...`);

    try {
      const user = auth.currentUser;
      if (!user) {
        toast({
          title: t("services_not_authenticated_title"),
          description: t("services_not_authenticated_description"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }

      const userId = user.uid;

      let profileFilename = existingProfile || null;
      let profileUrl = existingProfileUrl || null;

      // If a new profile image is selected, upload it
      if (serviceProfile) {
        const timestamp = Date.now();
        const fileName = `${userId}-${timestamp}-${serviceProfile.name}`;
        const storageRefService = ref(storage, `services/${fileName}`);

        // Create the file metadata
        const metadata = {
          contentType: serviceProfile.type,
        };

        // Upload the file
        const uploadTask = uploadBytesResumable(storageRefService, serviceProfile, metadata);

        // Monitor the upload progress
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Optional: Implement progress tracking if needed
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${progress}% done`);
            },
            (error) => {
              // Handle unsuccessful uploads
              console.error("Upload failed:", error);
              toast({
                title: t("upload_failed_title"),
                description: error.message || t("upload_failed_generic_error"),
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              reject(error);
            },
            () => {
              // Handle successful uploads on complete
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log("File available at", downloadURL);
                profileFilename = fileName;
                profileUrl = downloadURL;
                resolve();
              });
            }
          );
        });

        // Delete the previous service profile image if it exists and is being updated
        if (isEditMode && existingProfile) {
          const previousFileRef = ref(storage, `services/${existingProfile}`);
          try {
            await deleteObject(previousFileRef);
            console.log("Previous service profile deleted successfully.");
          } catch (deleteError) {
            console.error("Error deleting previous service profile:", deleteError);
            // Optionally notify the user
            
          }
        }
      }

      if (isEditMode) {
        // Update existing service in Firestore
        const serviceDocRef = doc(db, "services", editingServiceId);

        const updatedData = {
          service,
          price,
        };

        if (profileFilename) {
          updatedData.profile = profileFilename; // Storing the file name/path
        }

        await updateDoc(serviceDocRef, updatedData);
        console.log(t("services_update_success"));
        setSuccess(t("services_update_success"));

        // Fetch the updated download URL if profile was updated
        let updatedProfileUrl = null;
        if (profileFilename) {
          try {
            const profileRef = ref(storage, `services/${profileFilename}`);
            updatedProfileUrl = await getDownloadURL(profileRef);
          } catch (error) {
            console.error(`Error fetching updated profile image for service ${editingServiceId}:`, error);
          }
        }

        // Update local services state
        setServices((prevServices) =>
          prevServices.map((srv) =>
            srv.id === editingServiceId
              ? { 
                  ...srv, 
                  service, 
                  price, 
                  profile: profileFilename || srv.profile, 
                  profileUrl: updatedProfileUrl || srv.profileUrl 
                }
              : srv
          )
        );

        // Reset form
        setIsEditMode(false);
        setEditingServiceId(null);
        setService("");
        setPrice("");
        setServiceProfile(null);
        setProfilePreview(null);
        setExistingProfile(null);
        setExistingProfileUrl(null);
      } else {
        // Add new service to Firestore
        console.log(t("services_adding_service_to_firestore"));
        const newServiceData = {
          service,
          price,
          createdAt: new Date().toISOString(),
        };

        if (profileFilename) {
          newServiceData.profile = profileFilename;
        }

        const docRef = await addDoc(collection(db, "services"), newServiceData);
        console.log(t("services_added_successfully"));
        setSuccess(t("services_add_success"));

        // Fetch the download URL for the newly added profile
        let newProfileUrl = null;
        if (profileFilename) {
          try {
            const profileRef = ref(storage, `services/${profileFilename}`);
            newProfileUrl = await getDownloadURL(profileRef);
          } catch (error) {
            console.error(`Error fetching profile image for new service ${docRef.id}:`, error);
          }
        }

        // Update the local services state to include the new service
        setServices((prevServices) => [
          ...prevServices,
          {
            id: docRef.id,
            ...newServiceData,
            profileUrl: newProfileUrl,
          },
        ]);

        // Reset form fields
        setService("");
        setPrice("");
        setServiceProfile(null);
        setProfilePreview(null);
      }
    } catch (err) {
      console.error(`${isEditMode ? t("services_update_error") : t("services_add_error")}:`, err);
      let errorMessage = t("services_unexpected_error");

      // Firebase-specific error handling
      if (err.code === "permission-denied") {
        errorMessage = t("services_permission_denied");
      } else if (err.code === "unavailable") {
        errorMessage = t("services_unavailable");
      } else if (err.message) {
        errorMessage = err.message;
      }

      toast({
        title: t("services_error_title"),
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle edit action from the table
  const handleEditService = (serviceData) => {
    setIsEditMode(true);
    setEditingServiceId(serviceData.id);
    setService(serviceData.service);
    setPrice(serviceData.price);
    setExistingProfile(serviceData.profile || null);
    setExistingProfileUrl(serviceData.profileUrl || null);
    setServiceProfile(null);
    setProfilePreview(null);
    setError("");
    setSuccess("");
  };

  // Handle delete action from the table
  const handleDeleteService = async (serviceId) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      // Find the service to delete
      const serviceToDelete = services.find((srv) => srv.id === serviceId);
      if (serviceToDelete && serviceToDelete.profile) {
        const fileRef = ref(storage, `services/${serviceToDelete.profile}`);
        await deleteObject(fileRef);
        console.log("Service profile image deleted successfully.");
      }

      // Delete service from Firestore
      await deleteDoc(doc(db, "services", serviceId));
      console.log(t("services_delete_success"));
      setSuccess(t("services_delete_success"));

      // Update local services state
      setServices((prevServices) => prevServices.filter((srv) => srv.id !== serviceId));
    } catch (err) {
      console.error(t("services_delete_error"), err);
      toast({
        title: t("services_delete_error_title"),
        description: t("services_delete_error_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {/* Services Table */}
        <PricesTable
          tableData={services}
          fetching={fetchingServices}
          fetchError={fetchError}
          onEditUser={handleEditService} // Pass the edit handler
          onDeleteUser={handleDeleteService} // Pass the delete handler
        />

        {/* Add / Edit Service Form */}
        <Card mb={{ base: "0px", "2xl": "20px" }} boxShadow={cardShadow}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            {isEditMode ? t("services_edit_service") : t("services_add_new_service")}
          </Text>
          <HSeparator />

          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl as="form" onSubmit={handleSubmit}>
              {/* Service Name Field */}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="1px"
              >
                {t("services_service_name")} <Text color="red.500">*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder={t("services_service_name_placeholder")}
                mb="8px"
                fontWeight="500"
                size="sm"
                value={service}
                onChange={(e) => setService(e.target.value)}
              />

              {/* Price Field */}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="1px"
              >
                {t("services_price_in_chf")} <Text color="red.500">*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="number"
                placeholder={t("services_price_placeholder")}
                mb="8px"
                fontWeight="500"
                size="sm"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />

              {/* Service Profile Field */}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="1px"
              >
                {t("services_service_profile")} <Text color="red.500">{!isEditMode && '*'}</Text>
              </FormLabel>
              <Input
                isRequired={!isEditMode} // Make it required only when adding a new service
                variant="outline"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="file"
                accept="image/*"
                mb="8px"
                fontWeight="500"
                size="sm"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setServiceProfile(e.target.files[0]);
                    setProfilePreview(URL.createObjectURL(e.target.files[0]));
                  }
                }}
              />

              {/* Image Preview */}
              {profilePreview && (
                <Box mb="8px" display="flex" alignItems="center">
                  <Avatar
                    src={profilePreview}
                    size="lg"
                    name={service}
                    mr="4"
                  />
                  <Text fontSize="sm">{t("services_image_preview")}:</Text>
                </Box>
              )}

              {/* Existing Image Preview in Edit Mode */}
              {isEditMode && existingProfileUrl && !profilePreview && (
                <Box mb="8px" display="flex" alignItems="center">
                  <Avatar
                    src={existingProfileUrl} // Use the fetched download URL
                    size="lg"
                    name={service}
                    mr="4"
                  />
                  <Text fontSize="sm">{t("services_current_profile")}:</Text>
                </Box>
              )}

              {/* Display Error Message */}
              {error && (
                <Alert status="error" mb="8px">
                  <AlertIcon />
                  {error}
                </Alert>
              )}

              {/* Display Success Message */}
              {success && (
                <Alert status="success" mb="8px">
                  <AlertIcon />
                  {success}
                </Alert>
              )}

              {/* Submit/Update Button */}
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50px"
                mb="24px"
                type="submit"
                isDisabled={loading}
                leftIcon={
                  loading ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    <FcGoogle size={20} />
                  )
                }
              >
                {loading
                  ? isEditMode
                    ? t("services_updating_service")
                    : t("services_adding_service")
                  : isEditMode
                  ? t("services_update_service")
                  : t("services_add_new_service")}
              </Button>

              {/* Cancel Edit Button */}
              {isEditMode && (
                <Button
                  fontSize="sm"
                  variant="outline"
                  colorScheme="red"
                  fontWeight="500"
                  w="100%"
                  h="50px"
                  type="button"
                  onClick={() => {
                    // Reset form to add mode
                    setIsEditMode(false);
                    setEditingServiceId(null);
                    setService("");
                    setPrice("");
                    setServiceProfile(null);
                    setProfilePreview(null);
                    setExistingProfile(null);
                    setExistingProfileUrl(null);
                    setError("");
                    setSuccess("");
                  }}
                >
                  {t("services_cancel_edit")}
                </Button>
              )}
            </FormControl>
          </Flex>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
