// src/components/Users.js

// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner,
  Alert,
  AlertIcon,
  Select,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import UsersTable from "./components/UsersTable"; // Adjust the path as necessary
import Card from "components/card/Card.js";
import { HSeparator } from "components/separator/Separator";

// Firebase imports
import { auth, db } from "firebaseConfig"; // Adjust the path as necessary
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, collection, getDocs, updateDoc, deleteDoc } from "firebase/firestore";

// Icons
import { FaPlus, FaEdit } from "react-icons/fa"; // Appropriate icons for Add and Edit
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";

import { useTranslation } from 'react-i18next'; // Import useTranslation

export default function Users() {
  const { t } = useTranslation(); // Initialize the translation hook
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const toast = useToast();

  // State management for form fields and feedback
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("Enabled"); // Default status
  const [userType, setUserType] = useState("Standard"); // Updated User Type state
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  // State for users data
  const [users, setUsers] = useState([]);
  const [fetchingUsers, setFetchingUsers] = useState(true);
  const [fetchError, setFetchError] = useState("");

  // State to manage edit mode
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);

  // State for delete confirmation modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userToDelete, setUserToDelete] = useState(null);

  // Fetch users from Firestore on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      setFetchingUsers(true);
      setFetchError("");

      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (err) {
        console.error("Error fetching users:", err);
        setFetchError(t("users_fetch_error"));
      } finally {
        setFetchingUsers(false);
      }
    };

    fetchUsers();
  }, [t]);

  // Handle form submission to add or update a user
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // Basic validation
    if (
      !name ||
      !email ||
      (!isEditMode && (!password || !confirmPassword)) ||
      !phone ||
      !userType // Ensure userType is selected
    ) {
      toast({
        title: t("users_missing_info_title"),
        description: t("users_missing_info_description"),
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isEditMode && password !== confirmPassword) {
      toast({
        title: t("users_password_mismatch_title"),
        description: t("users_password_mismatch_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Password strength validation only for adding
    if (!isEditMode) {
      const passwordRegex = /^.{6,}$/;
      if (!passwordRegex.test(password)) {
        toast({
          title: t("users_weak_password_title"),
          description: t("users_weak_password_description"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    // Validate phone number
    if (!isValidPhoneNumber(phone)) {
      toast({
        title: t("users_invalid_phone_title"),
        description: t("users_invalid_phone_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Modification 1: Ensure at least one other Admin exists when changing from Admin to Standard
    if (isEditMode) {
      const originalUser = users.find((u) => u.id === editingUserId);
      if (originalUser.userType === "Admin" && userType === "Standard") {
        const adminCount = users.filter(
          (u) => u.userType === "Admin" && u.id !== editingUserId
        ).length;
        if (adminCount < 1) {
          toast({
            title: t("users_operation_not_allowed_title"),
            description: t("users_operation_not_allowed_description"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }
    }

    // Modification 2: Prevent Admin users from being Disabled
    if (userType === "Admin" && status === "Disabled") {
      toast({
        title: t("users_invalid_status_title"),
        description: t("users_invalid_status_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    console.log(`${isEditMode ? t("users_updating_user") : t("users_adding_user")}...`);

    try {
      if (isEditMode) {
        // Update existing user in Firestore
        const userDocRef = doc(db, "users", editingUserId);
        await updateDoc(userDocRef, {
          name,
          email,
          phone,
          status,
          userType, // Include userType in update
        });
        console.log(t("users_update_success"));
        setSuccess(t("users_update_success"));

        // Update local users state
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === editingUserId
              ? { ...user, name, email, phone, status, userType }
              : user
          )
        );

        // Reset form
        setIsEditMode(false);
        setEditingUserId(null);
        setName("");
        setEmail("");
        setPhone("");
        setStatus("Enabled");
        setUserType("Standard"); // Reset to default
      } else {
        // Create new user in Firebase Authentication
        console.log(t("users_creating_user"));
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        console.log(t("users_created_successfully", { uid: user.uid }));

        // Attempt to store additional user data in Firestore
        console.log(t("users_storing_data_firestore"));
        try {
          await setDoc(doc(db, "users", user.uid), {
            name,
            email,
            phone,
            status, // Default or selected status
            userType, // Include userType in Firestore data
            profile: "default_profile.png",
            createdAt: new Date().toISOString(),
          });
          console.log(t("users_firestore_success"));
          setSuccess(t("users_add_success"));

          // Update the local users state to include the new user
          setUsers((prevUsers) => [
            ...prevUsers,
            {
              id: user.uid,
              name,
              email,
              phone,
              status,
              userType, // Include userType in local state
              profile: "default_profile.png",
              createdAt: new Date().toISOString(),
            },
          ]);

          // Reset form fields
          setName("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setPhone("");
          setStatus("Enabled");
          setUserType("Standard"); // Reset to default
        } catch (firestoreError) {
          console.error(t("users_firestore_error"), firestoreError);
          toast({
            title: t("users_firestore_error_title"),
            description: t("users_firestore_error_description"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (err) {
      console.error(`${isEditMode ? t("users_update_error") : t("users_add_error")}:`, err);
      let errorMessage = t("users_unexpected_error");

      if (err.code) {
        switch (err.code) {
          case "auth/email-already-in-use":
            errorMessage = t("users_email_in_use");
            break;
          case "auth/invalid-email":
            errorMessage = t("users_invalid_email");
            break;
          case "auth/weak-password":
            errorMessage = t("users_weak_password");
            break;
          case "auth/network-request-failed":
            errorMessage = t("users_network_error");
            break;
          default:
            errorMessage = `${t("users_error")}: ${err.message}`;
        }
      }

      toast({
        title: t("users_error_title"),
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Handle edit action from the table
  const handleEditUser = (user) => {
    setIsEditMode(true);
    setEditingUserId(user.id);
    setName(user.name);
    setEmail(user.email);
    setPhone(user.phone);
    setStatus(user.status);
    setUserType(user.userType || "Standard"); // Set userType, default if not present
    setError("");
    setSuccess("");
  };

  // Handle delete action from the table
  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    onOpen();
  };

  // Confirm deletion
  const confirmDeleteUser = async () => {
    if (!userToDelete) return;

    setLoading(true);
    
    try {
      // Delete user document from Firestore
      await deleteDoc(doc(db, "users", userToDelete.id));

      // Optionally, delete user from Firebase Authentication if needed
      // Note: Deleting from Firebase Auth requires admin privileges or a secure server environment
      // This example assumes deletion only from Firestore

      // Update local users state
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id));

      toast({
        title: t("users_delete_success_title"),
        description: t("users_delete_success_description"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setUserToDelete(null);
      onClose();
    } catch (err) {
      console.error("Error deleting user:", err);
      toast({
        title: t("users_delete_error_title"),
        description: t("users_delete_error_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle cancel deletion
  const cancelDeleteUser = () => {
    setUserToDelete(null);
    onClose();
  };

  return (
    <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {/* Users Table */}
        <UsersTable
          tableData={users}
          fetching={fetchingUsers}
          fetchError={fetchError}
          onEditUser={handleEditUser} // Pass the edit handler
          onDeleteUser={handleDeleteUser} // Pass the delete handler
        />

        {/* Add / Edit User Form */}
        <Card mb={{ base: "0px", "2xl": "20px" }} boxShadow={cardShadow}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            {isEditMode ? t("users_edit_user") : t("users_add_new_user")}
          </Text>
          <HSeparator />

          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl as="form" onSubmit={handleSubmit}>
              {/* Name Field */}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="1px"
              >
                {t("users_name")}
                <Text color="red.500">*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder={t("users_name_placeholder")}
                mb="8px"
                fontWeight="500"
                size="sm"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              {/* Email Field */}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="1px"
              >
                {t("users_email")}
                <Text color="red.500">*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder={t("users_email_placeholder")}
                mb="8px"
                fontWeight="500"
                size="sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isReadOnly={isEditMode} // Prevent editing email during edit
                cursor={isEditMode ? "not-allowed" : "auto"}
                bg={isEditMode ? "gray.100" : "white"}
              />

              {/* Password Field (Only for Adding) */}
              {!isEditMode && (
                <>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    display="flex"
                  >
                    {t("users_password")}
                    <Text color="red.500">*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      isRequired={!isEditMode}
                      fontSize="sm"
                      placeholder={t("users_password_placeholder")}
                      mb="8px"
                      size="sm"
                      type="password"
                      variant="auth"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <InputRightElement display="flex" alignItems="center" mt="4px">
                      {/* You can add an icon or any element here if needed */}
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    mb="1px"
                  >
                    {t("users_confirm_password")}
                    <Text color="red.500">*</Text>
                  </FormLabel>
                  <Input
                    isRequired={!isEditMode}
                    fontSize="sm"
                    placeholder={t("users_confirm_password_placeholder")}
                    mb="8px"
                    size="sm"
                    type="password"
                    variant="auth"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </>
              )}

              {/* Phone Number Field using react-phone-input-2 */}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="1px"
              >
                {t("users_phone_number")}
                <Text color="red.500">*</Text>
              </FormLabel>
              <PhoneInput
                country={"ch"} // Sets default to Switzerland
                value={phone}
                onChange={(phoneNumber, country, e, formattedValue) => {
                  setPhone(`+${phoneNumber}`); // Ensure phone starts with '+'
                }}
                inputStyle={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "48px",
                  borderRadius: "4px",
                  borderColor: "#E2E8F0", // Chakra UI's gray.300
                }}
                buttonStyle={{
                  borderColor: "#E2E8F0",
                }}
                containerStyle={{ width: "100%", marginBottom: "8px" }}
                disableSearch={false}
                placeholder={t("users_phone_placeholder")}
              />

              {/* User Type Field */}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="1px"
              >
                {t("users_user_type")}
                <Text color="red.500">*</Text>
              </FormLabel>
              <Select
                placeholder={t("users_select_user_type")}
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                mb="8px"
                size="sm"
                isRequired
              >
                <option value="Standard">{t("users_standard")}</option>
                <option value="Admin">{t("users_admin")}</option>
              </Select>

              {/* Status Field (Only for Editing) */}
              {isEditMode && (
                <>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    mb="1px"
                  >
                    {t("users_status")}
                    <Text color="red.500">*</Text>
                  </FormLabel>
                  <Select
                    placeholder={t("users_select_status")}
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    mb="8px"
                    size="sm"
                  >
                    <option value="Enabled">{t("users_enabled")}</option>
                    {/* Modification 2: Disable 'Disabled' option if userType is 'Admin' */}
                    <option value="Disabled" disabled={userType === "Admin"}>
                      {t("users_disabled")}
                    </option>
                  </Select>
                </>
              )}

              {/* Display Error Message */}
              {error && (
                <Alert status="error" mb="8px">
                  <AlertIcon />
                  {error}
                </Alert>
              )}

              {/* Display Success Message */}
              {success && (
                <Alert status="success" mb="8px">
                  <AlertIcon />
                  {success}
                </Alert>
              )}

              {/* Submit/Update Button */}
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50px"
                mb="24px"
                type="submit"
                isDisabled={loading}
                leftIcon={
                  loading ? (
                    <Spinner size="sm" color="white" />
                  ) : isEditMode ? (
                    <FaEdit />
                  ) : (
                    <FaPlus />
                  )
                }
              >
                {loading
                  ? isEditMode
                    ? t("users_updating_user")
                    : t("users_adding_user")
                  : isEditMode
                  ? t("users_update_user")
                  : t("users_add_new_user")}
              </Button>

              {/* Cancel Edit Button */}
              {isEditMode && (
                <Button
                  fontSize="sm"
                  variant="outline"
                  colorScheme="red"
                  fontWeight="500"
                  w="100%"
                  h="50px"
                  type="button"
                  onClick={() => {
                    // Reset form to add mode
                    setIsEditMode(false);
                    setEditingUserId(null);
                    setName("");
                    setEmail("");
                    setPhone("");
                    setStatus("Enabled");
                    setUserType("Standard"); // Reset to default
                    setError("");
                    setSuccess("");
                  }}
                  leftIcon={<FaEdit />} // Optional: add an icon to the cancel button
                >
                  {t("users_cancel_edit")}
                </Button>
              )}
            </FormControl>
          </Flex>
        </Card>
      </SimpleGrid>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={cancelDeleteUser}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("users_delete_confirmation_title")}</ModalHeader>
          <ModalBody>
            {userToDelete && (
              <Text>
                {t("users_delete_confirmation_message", { name: userToDelete.name })}
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={cancelDeleteUser}>
              {t("users_cancel")}
            </Button>
            <Button colorScheme="red" onClick={confirmDeleteUser}>
              {t("users_delete")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
