// src/components/navbar/Navbar.js

// Chakra Imports
import {
	Box,
	Flex,
	Text,
	useColorModeValue,
  } from '@chakra-ui/react';
  import PropTypes from 'prop-types';
  import React, { useState, useEffect } from 'react';
  import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
  import { useLocation } from 'react-router-dom';
  import routes from 'routes';
  import { useTranslation } from 'react-i18next'; // Import useTranslation
  
  export default function AdminNavbar(props) {
	const [scrolled, setScrolled] = useState(false);
	const { t } = useTranslation(); // Initialize the translation hook
  
	useEffect(() => {
	  const changeNavbar = () => {
		if (window.scrollY > 1) {
		  setScrolled(true);
		} else {
		  setScrolled(false);
		}
	  };
  
	  window.addEventListener('scroll', changeNavbar);
  
	  return () => {
		window.removeEventListener('scroll', changeNavbar);
	  };
	}, []);
  
	const { secondary, message, brandText } = props;
  
	// Chakra UI Color Modes
	const mainText = useColorModeValue('navy.700', 'white');
	// Solid background based on color mode
	const navbarBg = useColorModeValue('white', 'navy.800');
  
	// Get current route name
	const location = useLocation();
	const currentPath = location.pathname;
	const currentRoute = routes.find(
	  (route) => route.layout + route.path === currentPath
	);
	const currentRouteName = currentRoute ? t(currentRoute.name) : t('route_dashboard');
  
	return (
	  <Box
		position="fixed"
		top="0"
		left={{ base: '0', xl: '300px' }} // Position navbar to the right of the sidebar on xl screens and above
		boxShadow="sm" // Subtle shadow for separation
		bg={navbarBg} // Solid background color
		borderBottom="1px solid"
		borderColor={useColorModeValue('gray.200', 'gray.700')}
		width={{ base: '100%', xl: 'calc(100% - 300px)' }} // Adjust width based on sidebar
		height="60px" // Fixed height for consistency
		zIndex="1000" // Ensure navbar is above other elements
		display="flex"
		alignItems="center"
		justifyContent="space-between"
		px={{ base: '15px', md: '20px' }}
		transition="left 0.3s ease, width 0.3s ease"
	  >
		<Flex
		  alignItems="center"
		  justifyContent="space-between"
		  width="100%"
		  height="100%"
		>
		  {/* Left Section: Current Route Name */}
		  <Text
			color={mainText}
			fontSize="lg"
			fontWeight="bold"
			textAlign="left"
			flex="1"
		  >
			{currentRouteName}
		  </Text>
  
		  {/* Right Section: Navbar Links */}
		  <Box>
			<AdminNavbarLinks
			  onOpen={props.onOpen}
			  logoText={props.logoText}
			  secondary={props.secondary}
			  fixed={props.fixed}
			  scrolled={scrolled}
			/>
		  </Box>
		</Flex>
  
		{/* Optional Message Display */}
		{secondary && (
		  <Text
			color="white"
			position="absolute"
			bottom="-20px"
			left="50%"
			transform="translateX(-50%)"
			fontSize="sm"
		  >
			{message}
		  </Text>
		)}
	  </Box>
	);
  }
  
  AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func,
  };
  